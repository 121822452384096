import React from 'react'
import { Box, Typography, useTheme } from '@mui/material'

function InvalidEngineContent (): React.JSX.Element {
  const { palette } = useTheme()
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '90vh',
        color: palette.tableHeaderText.main
      }}
    >
      <Typography>Invalid Risk Engine</Typography>
    </Box>
  )
}

export default InvalidEngineContent
