import React from 'react'
import { TableRow, TableCell, Typography, useTheme } from '@mui/material'

function AccountTableFooterRow (props: {
  name: string
  value: string
  isTopRow: boolean
  numColumns: number
}): React.JSX.Element {
  const { palette } = useTheme()
  const { name, value, isTopRow, numColumns } = props
  return (
    <TableRow sx={{ backgroundColor: palette.background.default }}>
      <TableCell
        colSpan={numColumns - 2}
        align='left'
        sx={{
          borderBottom: 'none',
          paddingBottom: '5px',
          paddingTop: '5px',
          borderTopStyle: isTopRow ? 'solid' : undefined,
          borderTopWidth: isTopRow ? '1px' : undefined
        }}
      >
        <Typography
          sx={{
            color: palette.tableHeaderText.main,
            fontSize: '1rem'
          }}
        >
          {name}
        </Typography>
      </TableCell>
      <TableCell
        colSpan={2}
        align='right'
        sx={{
          borderBottom: 'none',
          paddingBottom: '5px',
          paddingTop: '5px',
          borderTopStyle: isTopRow ? 'solid' : undefined,
          borderTopWidth: isTopRow ? '1px' : undefined
        }}
      >
        <Typography
          sx={{
            color: palette.tableHeaderText.main,
            fontSize: '1rem'
          }}
        >
          {value}
        </Typography>
      </TableCell>
    </TableRow>
  )
}

export default AccountTableFooterRow
