import React, { useState } from 'react'
import dayjs, { type Dayjs } from 'dayjs'
import { useNavigate } from 'react-router-dom'
import minMax from 'dayjs/plugin/minMax'
import utc from 'dayjs/plugin/utc'
import {
  Box,
  FormControlLabel,
  FormGroup,
  IconButton,
  Button,
  Stack,
  Switch,
  TableCell,
  TableRow,
  Typography,
  useTheme
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import RefreshIcon from '@mui/icons-material/Refresh'

import type { AccountRowObject, BalanceWithPrice } from '../types'
import { ValueCellWithColor } from './cells'
import { modifiedAccountColumns } from '../constants'
import RpmsBalancesSubTableRow from './RpmsBalancesSubTableRow'
import RpmsPositionsSubTableRow from './RpmsPositionsSubTableRow'
import PlatformRows from './PlatformRows'

function ToRiskButton (props: {
  accountId: string
  riskEngineSlug: string
}): React.JSX.Element {
  const { palette } = useTheme()
  const navigate = useNavigate()
  const { accountId, riskEngineSlug } = props
  return (
    <Button
      variant='outlined'
      size='small'
      onClick={() => { navigate(`/portfolio/balances/risk/${riskEngineSlug}/${accountId}`) }}
      sx={{
        color: palette.accent.main,
        borderColor: palette.accent.main,
        padding: '0px',
        fontSize: 9
      }}
    >
      See Risk
    </Button>
  )
}

function AccountRow (props: {
  accountId: string
  accountName: string
  containedRows: BalanceWithPrice[]
  includeSmallAmounts: boolean
  smallThreshold: number
  requestedTime?: Dayjs
  isOpen: boolean
  toggleOpen: () => void
  riskEngineSlug?: string
  showPlatformOption: boolean
}): React.JSX.Element {
  dayjs.extend(minMax)
  dayjs.extend(utc)
  const { palette } = useTheme()
  const {
    accountId,
    accountName,
    containedRows,
    requestedTime,
    isOpen,
    toggleOpen,
    smallThreshold,
    includeSmallAmounts,
    riskEngineSlug,
    showPlatformOption
  } = props
  const [usePlatformBalances, setUsePlatformBalances] = useState<boolean>(false)
  const [refreshSignal, setRefreshSignal] = useState<boolean>(false)

  const balanceRows = containedRows.filter((row) => row.instrumentIsAsset)
  const positionRows = containedRows.filter((row) => !row.instrumentIsAsset)

  const rowObject: AccountRowObject = {
    id: accountId,
    accountDisplay: accountName,
    requestedTime,
    oldestCashPriceTime: dayjs.min(balanceRows.filter((bal) => bal.instrumentIsCash).map((row) => dayjs.utc(row.priceTime))) ?? dayjs().utc(),
    cashValue: balanceRows.filter((bal) => bal.instrumentIsCash).reduce((partialSum, a) => partialSum + a.multiplier * (a.price ?? 0) * a.quantity, 0) ?? 0,
    oldestAssetPriceTime: dayjs.min(balanceRows.map((row) => dayjs.utc(row.priceTime))) ?? dayjs().utc(),
    assetsValue: balanceRows.reduce((partialSum, a) => partialSum + a.multiplier * (a.price ?? 0) * a.quantity, 0) ?? 0,
    oldestPositionPriceTime: dayjs.min(positionRows.map((row) => dayjs.utc(row.priceTime))) ?? dayjs().utc(),
    grossPositionsValue: positionRows.reduce((partialSum, a) => partialSum + Math.abs(a.multiplier * (a.price ?? 0) * a.quantity), 0) ?? 0
  }

  return (
    <>
      <TableRow>
        <TableCell sx={{ padding: 1 }}>
          <Stack direction='row'>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={toggleOpen}
            >
              {
                isOpen
                  ? <KeyboardArrowUpIcon fontSize='small'/>
                  : <KeyboardArrowDownIcon fontSize='small'/>
              }
            </IconButton>
            {
              isOpen && showPlatformOption && <FormGroup row>
                <FormControlLabel
                  control={<Switch
                    sx={{
                      '& .Mui-checked+.MuiSwitch-track': {
                        backgroundColor: palette.accent.main,
                        opacity: 0.9
                      },
                      '& .MuiSwitch-thumb': {
                        color: palette.primary.main
                      },
                      '& .MuiSwitch-track': {
                        backgroundColor: palette.tableBodyText.main
                      }
                    }}
                    color='default'
                    checked={usePlatformBalances}
                    onChange={() => { setUsePlatformBalances(!usePlatformBalances) }}
                  />}
                  label={<Typography
                    sx={{ color: palette.tableBodyText.main, fontSize: 11 }}
                  >Use Platform?</Typography>}
                  sx={{ color: palette.tableBodyText.main }}
                />
              </FormGroup>
            }
            {
              isOpen && usePlatformBalances && <IconButton
                onClick={() => { setRefreshSignal(!refreshSignal) }}
              >
                <RefreshIcon fontSize='small' style={{ color: palette.accent.main }} sx={{ margin: 0 }} />
              </IconButton>
            }
          </Stack>
        </TableCell>
        {
          modifiedAccountColumns.map((column, idx) => (
            column.id === 'accountDisplay' && riskEngineSlug !== undefined
              ? <TableCell
                align={column.valueDetails.alignment}
                sx={{
                  color: palette.tableBodyText.main,
                  fontSize: 14,
                  overflow: column.overflowType === 'tooltip' ? 'hidden' : 'auto'
                }}
                key={idx}
              >
                <Stack direction='row' spacing={3}>
                  <Box>
                    {rowObject.accountDisplay}
                  </Box>
                  <ToRiskButton
                    riskEngineSlug={riskEngineSlug}
                    accountId={accountId}
                  />
                </Stack>
              </TableCell>
              : <ValueCellWithColor<AccountRowObject, any>
                column={column}
                item={rowObject}
                key={idx}
                defaultTextColor={palette.tableBodyText.main}
                redTextColor='red'
                greenTextColor='green'
              />
          ))
        }
      </TableRow>
      {
        isOpen && !usePlatformBalances && <RpmsBalancesSubTableRow
          containedRows={balanceRows}
          colsToSpan={modifiedAccountColumns.length + 1}
          requestedTime={requestedTime}
          smallThreshold={smallThreshold}
          includeSmallAmounts={includeSmallAmounts}
        />
      }
      {
        isOpen && !usePlatformBalances && <RpmsPositionsSubTableRow
          containedRows={positionRows}
          colsToSpan={modifiedAccountColumns.length + 1}
          requestedTime={requestedTime}
          smallThreshold={smallThreshold}
          includeSmallAmounts={includeSmallAmounts}
        />
      }
      {
        isOpen && usePlatformBalances && <PlatformRows
          accountId={accountId}
          includeSmallAmounts={includeSmallAmounts}
          smallThreshold={smallThreshold}
          refreshSignal={refreshSignal}
        />
      }
    </>
  )
}

export default AccountRow
