import React, { useEffect, useState } from 'react'
import { Box, Stack, useTheme } from '@mui/material'
import dayjs, { type Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Modal, useRequestSnackbar } from '@r40cap/ui'
import { type Price, PriceCreator, priceApi } from '@r40cap/pms-sdk'

import PricingControlsPanel from './PricingControlsPanel'
import PricingTable from './PricingTable'
import MassManualPricingModal from './MassManualPricingModal'
import { isApiError } from '../../utils/errors'

function PricingWrapper (): React.JSX.Element {
  dayjs.extend(utc)
  const { palette } = useTheme()
  const [time, setTime] = useState<Dayjs | null>(null)
  const [showAll, setShowAll] = useState<boolean>(false)
  const [updateModalOpen, setUpdateModalOpen] = useState<boolean>(false)
  const [editedData, setEditedData] = useState<Price[]>([])
  const [addedList, setAddedList] = useState<string[]>([])
  const [latestData, setLatestData] = useState<Price[]>([])
  const [modalContent, setModalContent] = useState<React.JSX.Element>(<></>)
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const [queryParams, setQueryParams] = useState({
    time: time?.format('YYYY-MM-DD HH:mm') ?? undefined,
    force: false
  })
  const { showSnackbar } = useRequestSnackbar()

  const [postUpdatesMutation] = priceApi.useUpdatePricesMutation()

  const { data: pxData, refetch: pxRefetch, isFetching: pxIsFetching } = priceApi.useGetPricesQuery(queryParams, {
    skip: isInitialLoad
  })

  useEffect(() => {
    if (isInitialLoad) {
      setIsInitialLoad(false)
    }
  }, [isInitialLoad])

  useEffect(() => {
    setQueryParams({
      time: time?.format('YYYY-MM-DD HH:mm') ?? undefined,
      force: false
    })
  }, [time])

  const handleRefresh = (): void => {
    if (!isInitialLoad) {
      if (!queryParams.force) {
        setQueryParams((prevParams) => ({
          ...prevParams,
          force: true
        }))
      } else {
        pxRefetch().catch(() => {
          console.error('Error Refreshing')
        })
      }
    }
  }

  function handlePush (): void {
    const newAdditionObjects: Price[] = editedData.filter((data) => addedList.includes(data.id))
    if (newAdditionObjects.length > 0) {
      showSnackbar({
        isOpen: true,
        message: 'Updating Prices',
        status: 'processing'
      })
      postUpdatesMutation({ updates: newAdditionObjects })
        .then((value) => {
          if (isApiError(value.error)) {
            console.error(value.error.data)
            const msg = value.error.originalStatus === 400
              ? value.error.data
              : 'Unexpected Error, check logs'
            showSnackbar({
              isOpen: true,
              message: msg,
              status: 'error'
            })
          } else {
            showSnackbar({
              isOpen: true,
              message: 'Prices Updated',
              status: 'success'
            })
            setAddedList([])
          }
        })
        .catch((error): void => {
          showSnackbar({
            isOpen: true,
            message: 'Failed to update Prices',
            status: 'error'
          })
          console.error(error)
        })
    }
  }

  function appendData (price: Price): void {
    setEditedData(editedData.concat(price))
    setAddedList(addedList.concat(price.id))
  }

  useEffect(() => {
    if (pxData !== null && pxData !== undefined) {
      setEditedData(pxData.data)
    }
  }, [pxData])

  useEffect(() => {
    const filteredObjects = editedData.reduce((acc: Record<string, Price>, price: Price) => {
      const instrumentId = price.instrument.id
      if (acc[instrumentId] === undefined || price.time > acc[instrumentId].time || (price.time === acc[instrumentId].time && price.id < acc[instrumentId].id)) {
        acc[instrumentId] = price
      }
      return acc
    }, {})
    const latestArray = Object.values(filteredObjects)
    setLatestData(latestArray)
  }, [editedData])

  function openAddModal (
    instrumentId: string | null,
    prefillPriceStr: string,
    prefillDeltaStr: string
  ): void {
    let instId: string | null = null
    if (instrumentId !== null) {
      const foundPrice = latestData.find((price) => price.instrument.id === instrumentId)
      instId = foundPrice?.instrument.id ?? null
    }
    setModalContent(
      <Box
        sx={{
          width: '40vw',
          padding: '10px'
        }}
      >
        <PriceCreator
          mainColor={palette.accent.main}
          secondaryColor={palette.tableBodyText.main}
          submitFunction={(price: Price) => {
            appendData(price)
            setUpdateModalOpen(false)
          }}
          fixedBeta={1.0}
          prefillDelta={prefillDeltaStr === '' ? undefined : parseFloat(prefillDeltaStr)}
          prefillPrice={prefillPriceStr === '' ? undefined : parseFloat(prefillPriceStr)}
          prefillInstrumentId={instId}
          prefillTime={time}
        />
      </Box>
    )
    setUpdateModalOpen(true)
  }

  function openMassManualModal (): void {
    setModalContent(
      <Box
        sx={{
          width: '60vw',
          padding: '10px'
        }}
      >
        <MassManualPricingModal closeModal={() => {
          setModalContent(<></>)
          setUpdateModalOpen(false)
        }}/>
      </Box>
    )
    setUpdateModalOpen(true)
  }

  return (
    <>
      <Stack
        spacing={1}
        direction={'column'}
        width={'100%'}
        sx={{ paddingTop: '90px', paddingBottom: '10px', height: 'calc(100% - 90px - 10px)' }}
      >
        <Box sx={{ height: '7%' }}>
          <PricingControlsPanel
            currentTime={time}
            setTime={setTime}
            showAll={showAll}
            setShowAll={setShowAll}
            refreshFunction={handleRefresh}
            pushFunction={handlePush}
            stuffToPush={addedList.length > 0}
            openNewPriceModal={openAddModal}
            openMassManualModal={openMassManualModal}
          />
        </Box>
        <Box sx={{ height: '93%' }}>
          <PricingTable
            rows={((showAll as boolean)
              ? latestData
              : latestData.filter(
                (price) => price.instrument.pricingMethodName === 'Manual'
              ))?.map((price) => {
              return {
                id: price.id,
                instrument: price.instrument.displayTicker,
                instrumentId: price.instrument.id,
                time: price.time,
                price: price.price,
                beta: price.beta,
                delta: price.delta,
                priceDecimals: price.instrument.priceDecimals
              }
            })}
            isFetching={pxIsFetching}
            openNewPriceModal={openAddModal}
          />
        </Box>
      </Stack>
      <Modal
        open={updateModalOpen}
        handleClose={() => {
          setUpdateModalOpen(false)
          setModalContent(<></>)
        }}
      >
        {modalContent}
      </Modal>
    </>
  )
}

export default PricingWrapper
