import React from 'react'
import dayjs, { type Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import {
  Box,
  IconButton,
  Stack,
  TableCell,
  Tooltip,
  useTheme
} from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import CircleIcon from '@mui/icons-material/Circle'
import LaunchIcon from '@mui/icons-material/Launch'
import WarningIcon from '@mui/icons-material/Warning'

import type { ColumnDefinitionWithColor } from '../types'

interface ValueCellWithColorProps<R, I, D> {
  column: ColumnDefinitionWithColor<R, I, any, D>
  defaultTextColor: string
  redTextColor: string
  greenTextColor: string
  item: R
  onDoubleClick?: () => void
  onOpenModal?: () => void
  dense?: boolean
  blankIdValue?: D
}

function getStalenessContent (
  recievedTime: Dayjs | undefined,
  requestedTime: Dayjs | undefined,
  stalenessPrefix: string,
  stalenessColor: string
): React.JSX.Element | undefined {
  if (recievedTime === undefined) {
    return undefined
  }
  dayjs.extend(utc)
  const minDiff = (requestedTime ?? dayjs().utc()).diff(recievedTime, 'minutes')
  if (minDiff > 15) {
    return <Tooltip title={`${stalenessPrefix} ${minDiff} minutes old`}>
      <WarningIcon fontSize='small' sx={{ color: stalenessColor }}/>
    </Tooltip>
  } else {
    return undefined
  }
}

export function ValueCellWithColor<R, I, D=any> (props: ValueCellWithColorProps<R, I, D>): React.JSX.Element {
  const {
    column,
    item,
    onDoubleClick,
    dense,
    onOpenModal,
    blankIdValue,
    defaultTextColor,
    redTextColor,
    greenTextColor
  } = props
  const undefinedOverride = column.undefinedOverride
  const value = item[column.id]
  const isDense = dense ?? false
  const isReverseColor = column.reverseColor ?? false

  const { palette } = useTheme()

  const color = (
    !(column.colored as boolean) || typeof value !== 'number'
      ? defaultTextColor
      : value === 0
        ? defaultTextColor
        : value > 0
          ? (isReverseColor as boolean ? redTextColor : greenTextColor)
          : (isReverseColor as boolean ? greenTextColor : redTextColor)
  )

  const styles = {
    color,
    fontSize: 14,
    overflow: column.overflowType === 'tooltip' ? 'hidden' : 'auto',
    paddingTop: isDense ? 0 : undefined,
    paddingBottom: isDense ? 0 : undefined,
    paddingLeft: isDense ? 1 : undefined,
    paddingRight: isDense ? 1 : undefined
  }

  const colorToUse = column.indicatorColor ?? (
    column.indicatorColorId !== undefined
      ? item[column.indicatorColorId] as string
      : undefined
  )

  if (value === null || (value === undefined && undefinedOverride === undefined)) {
    return <TableCell sx={styles} onDoubleClick={onDoubleClick}/>
  }

  if (typeof value === 'boolean') {
    if (value) {
      return (
        <TableCell
          align={column.valueDetails.alignment}
          sx={styles}
          onDoubleClick={onDoubleClick}
        >
          <CheckIcon />
        </TableCell>
      )
    } else {
      return (<TableCell
        align={column.valueDetails.alignment}
        sx={styles}
        onDoubleClick={onDoubleClick}
      />)
    }
  }
  let decimals: number | undefined
  if (column.decimalsId !== undefined) {
    const key = column.decimalsId as keyof R
    decimals = item[key] as number | undefined
  } else if (column.decimals !== undefined) {
    decimals = column.decimals
  }
  const signString = typeof value === 'number' && value < 0 ? '-' : ''
  const valueString = value === undefined && undefinedOverride !== undefined
    ? undefinedOverride
    : decimals !== undefined && typeof value === 'number'
      ? Math.abs(value).toLocaleString('en-US', {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
      })
      : typeof value === 'number'
        ? Math.abs(value)
        : value as string
  let stalenessContent: React.JSX.Element | undefined
  if (column.stalenessInfo !== undefined) {
    const recievedKey = column.stalenessInfo.recievedTimeId as keyof R
    const requestedKey = column.stalenessInfo.requestedTimeId as keyof R
    const recievedTime = item[recievedKey] as Dayjs | undefined
    const requestedTime = item[requestedKey] as Dayjs | undefined
    stalenessContent = getStalenessContent(
      recievedTime,
      requestedTime,
      column.stalenessInfo.stalenessPrefix,
      palette.staleColor.main
    )
  }

  const contentString = valueString === '' ? valueString : `${signString}${column.valueDetails.prefix ?? ''}${valueString}${column.valueDetails.suffix ?? ''}`
  const coreContent: React.JSX.Element = column.overflowType === 'tooltip'
    ? <Tooltip title={contentString} arrow>
        <div style={{ whiteSpace: 'nowrap' }}>
          {contentString}
        </div>
      </Tooltip>
    : <div style={{ whiteSpace: column.overflowType === 'wrap' ? 'normal' : 'nowrap' }}>
        {contentString}
      </div>
  const nonStalenessContent = (
    onOpenModal !== undefined &&
    column.modalId !== undefined &&
    column.modalType !== undefined &&
    blankIdValue !== undefined &&
    item[column.modalId] !== blankIdValue
  )
    ? colorToUse === undefined
      ? <Stack direction={'row'} alignItems={'center'} sx={{ width: '100%' }}>
            <Box sx={{ width: '80%' }}>
              {coreContent}
            </Box>
            <Box sx={{ width: '20%' }}>
              <IconButton onClick={onOpenModal}>
                <LaunchIcon fontSize='small' />
              </IconButton>
            </Box>
          </Stack>
      : <Stack direction={'row'} alignItems={'center'} sx={{ width: '100%' }}>
            <Box sx={{ width: '10%' }}>
              <CircleIcon
                sx={{ color: colorToUse }}
              />
            </Box>
            <Box sx={{ width: '70%' }}>
              {coreContent}
            </Box>
            <Box sx={{ width: '20%' }}>
              <IconButton onClick={onOpenModal}>
                <LaunchIcon fontSize='small' />
              </IconButton>
            </Box>
          </Stack>
    : colorToUse === undefined
      ? coreContent
      : <Stack direction={'row'} alignItems={'center'} sx={{ width: '100%' }}>
            <Box sx={{ width: '10%' }}>
              <CircleIcon
                sx={{
                  color: colorToUse,
                  fontSize: 10,
                  justifySelf: 'center'
                }}
              />
            </Box>
            <Box sx={{ width: '90%' }}>
              {coreContent}
            </Box>
          </Stack>
  return (
    <TableCell
      align={column.valueDetails.alignment}
      sx={styles}
      onDoubleClick={onDoubleClick}
    >
      {
        stalenessContent === undefined
          ? nonStalenessContent
          : column.valueDetails.alignment === 'left'
            ? <Stack direction={'row'} spacing={1} justifyContent={'start'}>
              {nonStalenessContent} {stalenessContent}
            </Stack>
            : <Stack direction={'row'} spacing={1} justifyContent={'end'}>
              {stalenessContent} {nonStalenessContent}
            </Stack>
      }
    </TableCell>
  )
}
