export function getDecimals (price: number): [number, number] {
  let priceDecimals: number = 0
  let quantityDecimals: number = 0
  if (price < 0.00000001) {
    priceDecimals = 10
    quantityDecimals = 0
  } else if (price < 0.000001) {
    priceDecimals = 8
    quantityDecimals = 0
  } else if (price < 0.0001) {
    priceDecimals = 6
    quantityDecimals = 0
  } else if (price < 0.01) {
    priceDecimals = 4
    quantityDecimals = 0
  } else if (price < 1) {
    priceDecimals = 2
    quantityDecimals = 2
  } else if (price < 10) {
    priceDecimals = 2
    quantityDecimals = 2
  } else if (price < 100) {
    priceDecimals = 2
    quantityDecimals = 4
  } else if (price < 10000) {
    priceDecimals = 2
    quantityDecimals = 4
  } else {
    priceDecimals = 2
    quantityDecimals = 4
  }
  return [quantityDecimals, priceDecimals]
}
