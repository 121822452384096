import React from 'react'
import { TableFooter, TableRow, TableCell, useTheme } from '@mui/material'
import FunctionsIcon from '@mui/icons-material/Functions'
import { RowSkeleton, ValueCell } from '@r40cap/ui'

import type { ColumnDefinitionWithStaleness } from '../types'

function PositionsTableFooter<T> (props: {
  totalObject?: T
  isLoading: boolean
  columns: Array<ColumnDefinitionWithStaleness<T, any>>
}): React.JSX.Element {
  const { totalObject, isLoading, columns } = props
  const { palette } = useTheme()

  if (
    isLoading ||
    totalObject === undefined
  ) {
    return (
      <TableFooter sx={{ position: 'sticky', bottom: 0 }}>
        <RowSkeleton
          usedKey={-1}
          columns={columns}
          frontBuffer={{
            key: 'toggle',
            alignment: 'center',
            variant: 'rectangular'
          }}
        />
      </TableFooter>
    )
  } else {
    return (
      <TableFooter sx={{ position: 'sticky', bottom: 0 }}>
        <TableRow sx={{ backgroundColor: palette.tertiary.main }}>
          <TableCell>
            <FunctionsIcon />
          </TableCell>
          {columns.map((column, index) => (
            <ValueCell<T, any>
              column={column}
              item={totalObject}
              key={index}
              defaultTextColor={palette.tableBodyText.main}
              redTextColor='red'
              greenTextColor='green'
            />
          ))}
        </TableRow>
      </TableFooter>
    )
  }
}

export default PositionsTableFooter
