import React, { useEffect, useMemo, useState } from 'react'
import type { Dayjs } from 'dayjs'
import {
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material'
import {
  type Order,
  RowSkeleton,
  getComparator,
  stableSort
} from '@r40cap/ui'
import type { BalanceByAccount } from '@r40cap/pms-sdk'

import AccountRow from './rows/AccountRow'
import type {
  BalanceWithPrice,
  ColumnDefinitionWithStaleness,
  FirstLevelDef,
  AccountRowObject
} from './types'

function getUniqueAccounts (positions: BalanceByAccount[]): FirstLevelDef[] {
  const filteredObjects = positions.reduce((accumulator: Record<string, BalanceByAccount>, currentObject: BalanceByAccount) => {
    accumulator[currentObject.accountId] = currentObject
    return accumulator
  }, {})
  const uniqueObjects: BalanceByAccount[] = Object.values(filteredObjects)
  return uniqueObjects.map((psn) => {
    return {
      id: psn.accountId,
      name: `${psn.platformName} - ${psn.accountName}`
    }
  })
}

function BalancesByAccountTableBody (props: {
  positions: BalanceWithPrice[]
  isLoading: boolean
  order: Order
  orderBy: keyof AccountRowObject
  columns: Array<ColumnDefinitionWithStaleness<AccountRowObject, any>>
  includeSmallAmounts: boolean
  smallThreshold: number
  requestedTime?: Dayjs
  openedList: readonly string[]
  toggleAccount: (accountId: string) => void
  accountIdToRiskEngineSlug: Map<string, string>
  showPlatformOption: boolean
}): React.JSX.Element {
  const {
    positions,
    isLoading,
    order,
    orderBy,
    columns,
    includeSmallAmounts,
    smallThreshold,
    requestedTime,
    openedList,
    toggleAccount,
    accountIdToRiskEngineSlug,
    showPlatformOption
  } = props
  const [accounts, setAccounts] = useState<FirstLevelDef[]>(getUniqueAccounts(positions))

  const visibleAccounts = useMemo(
    () => {
      const filteredAccounts = includeSmallAmounts
        ? accounts
        : accounts.filter((acc) => {
          const accRows = positions.filter((val) => val.accountId === acc.id)
          const absMv = accRows.reduce((sum, current) => sum + Math.abs(current.multiplier * (current.price ?? 0) * current.quantity), 0)
          return absMv >= smallThreshold
        })
      return stableSort(filteredAccounts, (a, b) => {
        const accountABalances = positions.filter((row) => row.instrumentIsAsset && a.id === row.accountId)
        const accountACash = positions.filter((row) => row.instrumentIsCash && a.id === row.accountId)
        const accountAPositions = positions.filter((row) => !row.instrumentIsAsset && a.id === row.accountId)
        const psnA = positions.find((val) => val.accountId === a.id)
        const psnRowA: AccountRowObject = {
          id: psnA?.accountId ?? 'no-account',
          accountDisplay: psnA !== undefined ? `${psnA.platformName} - ${psnA.accountName}` : '',
          cashValue: accountACash.reduce((partialSum, a) => partialSum + a.multiplier * (a.price ?? 0) * a.quantity, 0) ?? 0,
          assetsValue: accountABalances.reduce((partialSum, a) => partialSum + a.multiplier * (a.price ?? 0) * a.quantity, 0) ?? 0,
          grossPositionsValue: accountAPositions.reduce((partialSum, a) => partialSum + Math.abs(a.multiplier * (a.price ?? 0) * a.quantity), 0) ?? 0
        }
        const accountBBalances = positions.filter((row) => row.instrumentIsAsset && b.id === row.accountId)
        const accountBCash = positions.filter((row) => row.instrumentIsCash && b.id === row.accountId)
        const accountBPositions = positions.filter((row) => !row.instrumentIsAsset && b.id === row.accountId)
        const psnB = positions.find((val) => val.accountId === b.id)
        const psnRowB: AccountRowObject = {
          id: psnB?.accountId ?? 'no-account',
          accountDisplay: psnB !== undefined ? `${psnB.platformName} - ${psnB.accountName}` : '',
          cashValue: accountBCash.reduce((partialSum, a) => partialSum + a.multiplier * (a.price ?? 0) * a.quantity, 0) ?? 0,
          assetsValue: accountBBalances.reduce((partialSum, a) => partialSum + a.multiplier * (a.price ?? 0) * a.quantity, 0) ?? 0,
          grossPositionsValue: accountBPositions.reduce((partialSum, a) => partialSum + Math.abs(a.multiplier * (a.price ?? 0) * a.quantity), 0) ?? 0
        }
        return getComparator(order, orderBy)({
          ...psnRowA,
          requestedTime: undefined,
          oldestCashPriceTime: undefined,
          oldestAssetPriceTime: undefined,
          oldestPositionPriceTime: undefined
        },
        {
          ...psnRowB,
          requestedTime: undefined,
          oldestCashPriceTime: undefined,
          oldestAssetPriceTime: undefined,
          oldestPositionPriceTime: undefined
        })
      })
    },
    [order, orderBy, positions, accounts, includeSmallAmounts, smallThreshold]
  )

  useEffect(() => {
    setAccounts(getUniqueAccounts(positions))
  }, [positions])

  if (isLoading || positions === undefined) {
    const rows = []
    for (let i = 0; i < 5; i++) {
      rows.push(
        <RowSkeleton
          usedKey={i}
          columns={columns}
          frontBuffer={{
            key: 'toggle',
            alignment: 'center',
            variant: 'rectangular'
          }}
          key={i}
        />
      )
    }
    return (
      <TableBody>
        {rows}
        <TableRow sx={{ height: '100%' }} />
      </TableBody>
    )
  } else {
    const rows = visibleAccounts.map((account, idx) => {
      return (
        <AccountRow
          accountId={account.id}
          accountName={account.name}
          containedRows={positions.filter((psn) => psn.accountId === account.id)}
          key={idx}
          includeSmallAmounts={includeSmallAmounts}
          smallThreshold={smallThreshold}
          requestedTime={requestedTime}
          isOpen={openedList.includes(account.id)}
          toggleOpen={() => { toggleAccount(account.id) }}
          riskEngineSlug={accountIdToRiskEngineSlug.get(account.id)}
          showPlatformOption={showPlatformOption}
        />
      )
    })
    if (rows.length > 0) {
      return (
        <TableBody>
          {rows}
          <TableRow sx={{ height: '100%' }} />
        </TableBody>
      )
    } else {
      return (
        <TableBody>
          <TableRow sx={{ height: '100%' }}>
            <TableCell
              colSpan={columns.length + 1}
              align='center'
            >
              <Typography sx={{ fontSize: 20 }}>No Data</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      )
    }
  }
}

export default BalancesByAccountTableBody
