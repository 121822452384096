export enum GlossaryInputType {
  NUMBER,
  INTEGER,
  BOOLEAN,
  TEXT,
  DATETIME,
  SUPER_STRATEGY,
  ENTITY,
  PRICING_METHOD,
  BASE_FX,
  INSTRUMENT_TYPE,
  COLOR,
  COUNTERPARTY,
  SETTLEMENT_INSTRUMENT,
  DESK
}

export interface TableProps {
  refreshSignal: boolean
  pushSignal: boolean
  includeDeprecated: boolean
  editedList: readonly string[]
  markEdited: (ids: readonly string[]) => void
  clearEdited: () => void
}

export interface SuperStrategyRow {
  id: string
  name: string
  description: string
  isDeprecated: boolean
}

export interface StrategyRow {
  id: string
  priorityLevel: number
  name: string
  shorthand: string
  color: string
  superStrategy: string
  description: string
  isDeprecated: boolean
}

export interface AccountRow {
  id: string
  name: string
  riskEngineSlug: string
  shorthand: string
  platform: string
  platformApiId: string
  platformSecretName: string
  associatedDesk: string
  isSelfCustody: boolean
  isComingled: boolean
  isExternallyVisible: boolean
  isDeprecated: boolean
}

export interface InstrumentRow {
  id: string
  displayTicker: string
  instrumentType: string
  baseFx: string
  counterparty: string
  settlementInstrument: string
  multiplier: number
  pricingMethod: string
  expiration: string
  isCash: boolean
  isCall: boolean
  strikePrice: string
  methodId: string
  quantityDecimals: number
  priceDecimals: number
  isDeprecated: boolean
}
