import React, { useEffect, useState, useMemo } from 'react'
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from '@mui/material'
import {
  HeaderCell,
  ValueCell,
  type Order,
  getComparator,
  stableSort,
  TableSkeleton,
  PaginationFooter
} from '@r40cap/ui'

import { priceColumns } from './constants'
import type { PriceRow } from './types'
import type { PaginationState } from '../common/types'

const ROWS_PER_PAGE = 30

interface PriceTableHeadProps {
  onRequestSort: (property: keyof PriceRow) => void
  order: Order
  orderBy: keyof PriceRow
}

function PriceTableHead (props: PriceTableHeadProps): React.JSX.Element {
  const {
    onRequestSort,
    order,
    orderBy
  } = props
  const { palette } = useTheme()

  const createSortHandler = (property: keyof PriceRow): void => {
    onRequestSort(property)
  }

  return (
    <TableHead>
      <TableRow>
        {priceColumns.map((column, idx) => (
          <HeaderCell<PriceRow, any>
            column={column}
            isActiveSort={orderBy === column.id}
            order={order}
            sortHandler={createSortHandler}
            key={idx}
            backgroundColor={palette.background.default}
            textColor={palette.tableHeaderText.main}
            activeTextColor={palette.accent.main}
          />
        ))}
        <TableCell/>
      </TableRow>
    </TableHead>
  )
}

function PriceTableBody (props: {
  rows: PriceRow[]
  openNewPriceModal: (instrumentId: string | null, prefillPriceStr: string, prefillDeltaStr: string) => void
  paginationState: PaginationState
}): React.JSX.Element {
  const { palette } = useTheme()
  const {
    rows,
    openNewPriceModal,
    paginationState
  } = props

  const visibleRows = rows.slice(
    (paginationState.pageNumber - 1) * paginationState.pageSize,
    paginationState.pageNumber * paginationState.pageSize
  )

  return <TableBody>
    {
      visibleRows.map((row, index) => {
        return (
          <TableRow
            key={index}
            sx={{
              cursor: 'pointer',
              backgroundColor:
                index % 2 === 0
                  ? palette.primary.main
                  : palette.tertiary.main
            }}
          >
            {priceColumns.map((column, idx) => (
              <ValueCell<PriceRow, any>
                column={column}
                item={row}
                key={idx}
                defaultTextColor={palette.tableBodyText.main}
                redTextColor='red'
                greenTextColor='green'
              />
            ))}
            <TableCell align='center'>
              <Button
                variant='outlined'
                onClick={() => {
                  openNewPriceModal(row.instrumentId, row.price.toString(), row.delta.toString())
                }}
                sx={{
                  backgroundColor: palette.accent.main,
                  color: palette.background.default
                }}
              >
                Update
              </Button>
            </TableCell>
          </TableRow>
        )
      })
    }
    {
      visibleRows.length === 0
        ? <TableRow sx={{ height: '100%' }}>
            <TableCell
              colSpan={priceColumns.length + 1}
              sx={{ justifyItems: 'center', alignItems: 'center' }}
            >
              <Typography textAlign={'center'}>No Data</Typography>
            </TableCell>
          </TableRow>
        : <TableRow sx={{ height: '100%' }} />
    }
  </TableBody>
}

function PricingTable (props: {
  rows: readonly PriceRow[]
  isFetching: boolean
  openNewPriceModal: (instrumentId: string | null, prefillPriceStr: string, prefillDeltaStr: string) => void
}): React.JSX.Element {
  const { palette } = useTheme()
  const {
    rows,
    isFetching,
    openNewPriceModal
  } = props
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<keyof PriceRow>('instrument')
  const [currentPage, setCurrentPage] = useState<number>(1)

  const handleRequestSort = (property: keyof PriceRow): void => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const visibleRows = useMemo(
    () => stableSort(rows, getComparator(order, orderBy)),
    [order, orderBy, rows]
  )

  function pageBackward (): void {
    setCurrentPage(Math.max(currentPage - 1, 1))
  }

  function skipBackward (): void {
    setCurrentPage(1)
  }

  function pageForward (): void {
    const maxPages = Math.ceil(rows.length / (ROWS_PER_PAGE ?? 10))
    setCurrentPage(Math.min(currentPage + 1, maxPages))
  }

  function skipForward (): void {
    const maxPages = Math.ceil(rows.length / (ROWS_PER_PAGE ?? 10))
    setCurrentPage(maxPages)
  }

  useEffect(() => {
    setCurrentPage(1)
  }, [rows.length])

  return (
    <TableContainer
      sx={{
        backgroundColor: palette.primary.main,
        borderRadius: '5px',
        height: '100%'
      }}
    >
      <Table
        stickyHeader
        sx={{
          tableLayout: 'fixed',
          height: '100%'
        }}
      >
        <PriceTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        {
          isFetching
            ? <TableSkeleton
                numRows={10}
                columns={priceColumns}
              />
            : <PriceTableBody
                rows={visibleRows}
                openNewPriceModal={openNewPriceModal}
                paginationState={{ pageNumber: currentPage, pageSize: ROWS_PER_PAGE }}
              />
        }
        {
          !isFetching && <PaginationFooter
            numRows={rows.length}
            currentPage={currentPage}
            rowsPerPage={ROWS_PER_PAGE}
            onPageBackward={pageBackward}
            onSkipBackward={skipBackward}
            onPageForward={pageForward}
            onSkipForward={skipForward}
            numColumns={priceColumns.length + 1}
            backgroundColor={palette.background.default}
            textColor={palette.tableHeaderText.main}
            isDense
          />
        }
      </Table>
    </TableContainer>
  )
}

export default PricingTable
