import React, { type MouseEvent, useEffect, useMemo, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import type { Dayjs } from 'dayjs'
import {
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from '@mui/material'
import {
  HeaderCell,
  type Order,
  ValueCell,
  stableSort,
  getComparator,
  TableSkeleton,
  PaginationFooter
} from '@r40cap/ui'
import { blotterApi } from '@r40cap/pms-sdk'

import { type TransactionRow } from '../common/types'
import { transactionColumns, ROWS_PER_PAGE } from './constants'
import type { BlotterFilterPiece } from '../types'
import type { PaginationState } from '../../common/types'
import { getTransactionLegs } from './utils'

interface TransactionTableHeadProps {
  numSelected: number
  rowCount: number
  onRequestSort: (property: keyof TransactionRow) => void
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void
  order: Order
  orderBy: keyof TransactionRow
  includeSettleButton: boolean
}

function TransactionTableHead (props: TransactionTableHeadProps): React.JSX.Element {
  const {
    numSelected,
    rowCount,
    onRequestSort,
    onSelectAllClick,
    order,
    orderBy,
    includeSettleButton
  } = props
  const { palette } = useTheme()

  return (
    <TableHead>
      <TableRow>
        {
          onSelectAllClick !== undefined && <TableCell padding='checkbox' align='center'>
            <Checkbox
              color='accent'
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              size="small"
            />
          </TableCell>
        }
        {transactionColumns.map((column, idx) => (
          <HeaderCell<TransactionRow, any>
            column={column}
            isActiveSort={orderBy === column.id}
            order={order}
            sortHandler={onRequestSort}
            key={idx}
            backgroundColor={palette.background.default}
            textColor={palette.tableHeaderText.main}
            activeTextColor={palette.accent.main}
          />
        ))}
        {includeSettleButton && <TableCell sx={{ width: '10%' }}/>}
        <TableCell sx={{ width: '10%' }}/>
      </TableRow>
    </TableHead>
  )
}

function TransactionTableBody (props: {
  rows: TransactionRow[]
  isSelected: (id: string) => boolean
  clickHandle?: (event: MouseEvent<unknown>, id: string) => void
  includeSettleButton: boolean
  paginationState: PaginationState
}): React.JSX.Element {
  const navigate = useNavigate()
  const { palette } = useTheme()
  const {
    rows,
    isSelected,
    clickHandle,
    includeSettleButton,
    paginationState
  } = props

  const visibleRows = rows.slice(
    (paginationState.pageNumber - 1) * paginationState.pageSize,
    paginationState.pageNumber * paginationState.pageSize
  )

  return <TableBody sx={{ height: '100%' }}>
    {
      visibleRows.map((row, index) => {
        const isItemSelected = isSelected(row.id)
        const labelId = `txn-table-checkbox-${index}`

        return (
          <TableRow
            key={index}
            sx={{
              cursor: 'pointer',
              backgroundColor:
                index % 2 === 0
                  ? palette.primary.main
                  : palette.tertiary.main
            }}
          >
            {
              clickHandle !== undefined && <TableCell padding='checkbox' align='center'>
                <Checkbox
                  color='accent'
                  size="small"
                  onClick={(event) => { clickHandle(event, row.id) }}
                  checked={isItemSelected}
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </TableCell>
            }
            {transactionColumns.map((column, idx) => (
              <ValueCell<TransactionRow, any>
                column={column}
                item={row}
                defaultTextColor={palette.tableBodyText.main}
                redTextColor='red'
                greenTextColor='green'
                key={idx}
              />
            ))}
            {
              includeSettleButton && <TableCell align='center' sx={{ width: '10%', padding: '0px' }}>
                <Button
                  variant='outlined'
                  onClick={() => { navigate(`/blotter/unsettled/settle/${row.id}`) }}
                  sx={{
                    width: '100%',
                    color: palette.accent.main,
                    outlineColor: palette.accent.main
                  }}
                >
                  Settle
                </Button>
              </TableCell>
            }
            <TableCell align='center' sx={{ width: '10%', padding: '0px' }}>
              <Button
                variant='outlined'
                onClick={() => { navigate(`/blotter/transactions/${row.id}`) }}
                sx={{
                  width: '100%',
                  color: palette.accent.main,
                  outlineColor: palette.accent.main
                }}
              >
                Details
              </Button>
            </TableCell>
          </TableRow>
        )
      })
    }
    {
      visibleRows.length === 0
        ? <TableRow sx={{ height: '100%' }}>
            <TableCell
              colSpan={transactionColumns.length + 2}
              sx={{ justifyItems: 'center', alignItems: 'center' }}
            >
              <Typography textAlign={'center'}>No Data</Typography>
            </TableCell>
          </TableRow>
        : <TableRow sx={{ height: '100%' }} />
    }
  </TableBody>
}

function TransactionsTable (props: {
  time: Dayjs | null
  refreshSignal: boolean
  selected: readonly string[]
  setSelected: (newSelected: readonly string[]) => void
  filters: readonly BlotterFilterPiece[]
  untaggedOnly: boolean
  includeFees: boolean
}): React.JSX.Element {
  const {
    time,
    refreshSignal,
    selected,
    setSelected,
    filters,
    untaggedOnly,
    includeFees
  } = props
  const { palette } = useTheme()
  const [rows, setRows] = useState<TransactionRow[]>([])
  const [order, setOrder] = useState<Order>('desc')
  const [orderBy, setOrderBy] = useState<keyof TransactionRow>('time')
  const [currentPage, setCurrentPage] = useState<number>(1)

  const accounts = filters.filter((filter) => filter.type === 'account')
  const strategies = filters.filter((filter) => filter.type === 'strategy')
  const instruments = filters.filter((filter) => filter.type === 'instrument')
  const baseFxs = filters.filter((filter) => filter.type === 'baseFx')
  const desks = filters.filter((filter) => filter.type === 'desk')

  const { data, refetch, isFetching } = blotterApi.useGetRecentTransactionsQuery({
    time: time?.format('YYYY-MM-DD HH:mm') ?? undefined,
    account: accounts.length > 0 ? accounts.map(acc => acc.piece.id).join(',') : undefined,
    strategy: strategies.length > 0 ? strategies.map(strat => strat.piece.id).join(',') : undefined,
    instrument: instruments.length > 0 ? instruments.map(inst => inst.piece.id).join(',') : undefined,
    baseFx: baseFxs.length > 0 ? baseFxs.map(base => base.piece.id).join(',') : undefined,
    desk: desks.length > 0 ? desks.map(desk => desk.piece.id).join(',') : undefined,
    untaggedOnly,
    includeFees
  })

  const visibleRows = useMemo(
    () => stableSort(rows, getComparator(order, orderBy)),
    [order, orderBy, rows]
  )

  useEffect(() => {
    refetch().catch((error) => { console.error(error) })
  }, [refreshSignal])

  useEffect(() => {
    if (data !== null && data !== undefined) {
      setRows(data.data.map((transaction) => {
        const legs = getTransactionLegs(transaction.legs)
        return {
          ...transaction,
          trader: transaction.trader !== undefined && transaction.trader !== null
            ? `${transaction.trader.lastName}, ${transaction.trader.firstName}`
            : '',
          legOne: legs.legOne ?? '',
          legTwo: legs.legTwo ?? ''
        }
      }))
    }
  }, [data])

  const handleClick = (event: MouseEvent<unknown>, id: string): void => {
    const selectedIndex = selected.indexOf(id)
    let newSelected: readonly string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }
    setSelected(newSelected)
  }

  const handleRequestSort = (property: keyof TransactionRow): void => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const isSelected = (id: string): boolean => selected.includes(id)

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.checked as boolean) {
      const newSelected = rows.map((n) => n.id)
      setSelected(newSelected)
    } else {
      setSelected([])
    }
  }

  function pageBackward (): void {
    setCurrentPage(Math.max(currentPage - 1, 1))
  }

  function skipBackward (): void {
    setCurrentPage(1)
  }

  function pageForward (): void {
    const maxPages = Math.ceil(rows.length / (ROWS_PER_PAGE ?? 10))
    setCurrentPage(Math.min(currentPage + 1, maxPages))
  }

  function skipForward (): void {
    const maxPages = Math.ceil(rows.length / (ROWS_PER_PAGE ?? 10))
    setCurrentPage(maxPages)
  }

  useEffect(() => {
    setCurrentPage(1)
  }, [rows.length])

  return (
    <>
      <TableContainer
        sx={{
          backgroundColor: palette.primary.main,
          borderRadius: '5px',
          height: '100%'
        }}
      >
        <Table
          stickyHeader
          sx={{
            tableLayout: 'fixed',
            height: '100%'
          }}
        >
          <TransactionTableHead
            numSelected={selected.length}
            rowCount={rows.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            onSelectAllClick={handleSelectAllClick}
            includeSettleButton={false}
          />
          {
            isFetching as boolean
              ? <TableSkeleton
                  numRows={10}
                  columns={transactionColumns}
                  frontBuffer={{
                    key: 'row-check',
                    variant: 'rectangular',
                    alignment: 'center'
                  }}
                  endBuffer={{
                    key: 'details-button',
                    variant: 'rectangular',
                    alignment: 'center'
                  }}
                />
              : <TransactionTableBody
                  rows={visibleRows}
                  isSelected={isSelected}
                  clickHandle={handleClick}
                  includeSettleButton={false}
                  paginationState={{ pageNumber: currentPage, pageSize: ROWS_PER_PAGE }}
                />
          }
          {
            !isFetching && <PaginationFooter
              numRows={rows.length}
              currentPage={currentPage}
              rowsPerPage={ROWS_PER_PAGE}
              onPageBackward={pageBackward}
              onSkipBackward={skipBackward}
              onPageForward={pageForward}
              onSkipForward={skipForward}
              numColumns={2 + transactionColumns.length}
              backgroundColor={palette.background.default}
              textColor={palette.tableHeaderText.main}
              isDense
            />
          }
        </Table>
      </TableContainer>
      <Outlet />
    </>
  )
}

export function UnsettledTransactionsTable (props: {
  time: Dayjs | null
  refreshSignal: boolean
}): React.JSX.Element {
  const {
    time,
    refreshSignal
  } = props
  const { palette } = useTheme()
  const [rows, setRows] = useState<TransactionRow[]>([])
  const [order, setOrder] = useState<Order>('desc')
  const [orderBy, setOrderBy] = useState<keyof TransactionRow>('time')
  const [currentPage, setCurrentPage] = useState<number>(1)

  const { data, refetch, isFetching } = blotterApi.useGetUnsettledTransactionsQuery({
    time: time?.format('YYYY-MM-DD HH:mm') ?? undefined
  })

  const visibleRows = useMemo(
    () => stableSort(rows, getComparator(order, orderBy)),
    [order, orderBy, rows]
  )

  useEffect(() => {
    refetch().catch((error) => { console.error(error) })
  }, [refreshSignal])

  useEffect(() => {
    if (data !== null && data !== undefined) {
      setRows(data.data.map((transaction) => {
        const legs = getTransactionLegs(transaction.legs)
        return {
          ...transaction,
          trader: transaction.trader !== undefined && transaction.trader !== null
            ? `${transaction.trader.lastName}, ${transaction.trader.firstName}`
            : '',
          legOne: legs.legOne ?? '',
          legTwo: legs.legTwo ?? ''
        }
      }))
    }
  }, [data])

  const handleRequestSort = (property: keyof TransactionRow): void => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  function pageBackward (): void {
    setCurrentPage(Math.max(currentPage - 1, 1))
  }

  function skipBackward (): void {
    setCurrentPage(1)
  }

  function pageForward (): void {
    const maxPages = Math.ceil(rows.length / (ROWS_PER_PAGE ?? 10))
    setCurrentPage(Math.min(currentPage + 1, maxPages))
  }

  function skipForward (): void {
    const maxPages = Math.ceil(rows.length / (ROWS_PER_PAGE ?? 10))
    setCurrentPage(maxPages)
  }

  useEffect(() => {
    setCurrentPage(1)
  }, [rows.length])

  return (
    <>
      <TableContainer
        sx={{
          backgroundColor: palette.primary.main,
          borderRadius: '5px',
          height: '100%'
        }}
      >
        <Table
          stickyHeader
          sx={{
            tableLayout: 'fixed',
            height: '100%'
          }}
        >
          <TransactionTableHead
            numSelected={0}
            rowCount={rows.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            includeSettleButton
          />
          {
            isFetching as boolean
              ? <TableSkeleton
                  numRows={10}
                  columns={transactionColumns}
                  frontBuffer={{
                    key: 'row-check',
                    variant: 'rectangular',
                    alignment: 'center'
                  }}
                  endBuffer={{
                    key: 'details-button',
                    variant: 'rectangular',
                    alignment: 'center'
                  }}
                />
              : <TransactionTableBody
                  rows={visibleRows}
                  isSelected={(idVal) => false}
                  includeSettleButton
                  paginationState={{ pageNumber: currentPage, pageSize: ROWS_PER_PAGE }}
                />
          }
          {
            !isFetching && <PaginationFooter
              numRows={rows.length}
              currentPage={currentPage}
              rowsPerPage={ROWS_PER_PAGE}
              onPageBackward={pageBackward}
              onSkipBackward={skipBackward}
              onPageForward={pageForward}
              onSkipForward={skipForward}
              numColumns={2 + transactionColumns.length}
              backgroundColor={palette.background.default}
              textColor={palette.tableHeaderText.main}
              isDense
            />
          }
        </Table>
      </TableContainer>
      <Outlet />
    </>
  )
}

export default TransactionsTable
