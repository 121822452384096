export enum PortfolioViewType {
  POSITIONS = 'positions',
  BALANCES = 'balances',
  LIABILITIES = 'liabilities',
  UNSETTLED = 'unsettled'
}

export interface PortfolioViewOption {
  type: PortfolioViewType
  display: string
}

export enum SingleReferenceTimeType {
  CURRENT,
  LAST_MARK,
  EOD
}

export interface SingleReferenceTimeOption {
  type: SingleReferenceTimeType
  label: string
  value: string
}

export interface BaseFxOption {
  label: string
  id: string
}

export interface DeskOption {
  label: string
  id: string
  doFilter: boolean
}
