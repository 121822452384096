import React, { useState } from 'react'
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate
} from 'react-router-dom'
import type { Dayjs } from 'dayjs'
import { Box, Stack } from '@mui/material'
import { useAuth } from '@r40cap/auth'
import { Modal, useRequestSnackbar } from '@r40cap/ui'
import { blotterApi } from '@r40cap/pms-sdk'

import NoAccess from './NoAccess'
import Page from './Page'
import BlotterControlsPanel from '../components/blotter/BlotterControlsPanel'
import type { BlotterFilterPiece, BlotterOption, BlotterFilterType } from '../components/blotter/types'
import {
  TransactionsTable,
  ExpensesTable,
  CapitalCallsTable,
  LoansTable,
  UnsettledTransactionsTable
} from '../components/blotter/tables'
import {
  AddCapitalCallModal,
  AddExpenseModal,
  AddLoanModal,
  AddTransactionModal
} from '../components/blotter/additions'
import {
  CapitalCallDetails,
  ExpenseDetails,
  LoanDetails,
  TransactionDetails
} from '../components/blotter/details'
import {
  CloseLoanModal,
  SettleTransactionModal
} from '../components/blotter/special'
import { isApiError } from '../utils/errors'

const REDIRECT_TYPE = 'transactions' as BlotterOption

function Blotter (): React.JSX.Element {
  const location = useLocation()
  const navigate = useNavigate()
  const authContext = useAuth()
  const { showSnackbar } = useRequestSnackbar()

  const [refreshSignal, setRefreshSignal] = useState<boolean>(false)
  const [time, setTime] = useState<Dayjs | null>(null)
  const [untaggedOnly, setUntaggedOnly] = useState<boolean>(false)
  const [includeFees, setIncludeFees] = useState<boolean>(false)
  const [filters, setFilters] = useState<BlotterFilterPiece[]>([])
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [modalContent, setModalContent] = useState<React.JSX.Element | null>(null)
  const [selected, setSelected] = useState<readonly string[]>([])
  const [postTaggings] = blotterApi.useTagTransactionsMutation()

  function closeModal (): void {
    setModalContent(null)
    setModalOpen(false)
  }

  function openModal (content: React.JSX.Element): void {
    setModalContent(content)
    setModalOpen(true)
  }

  const viewType = (location.pathname.split('/')[2] ?? REDIRECT_TYPE) as BlotterOption

  function tagTransactions (strategyId: string, deskId: string): void {
    showSnackbar({
      isOpen: true,
      message: 'Adding Capital Call',
      status: 'processing'
    })
    postTaggings({
      taggings: selected.map((transactionId) => ({
        transactionId,
        strategyId,
        deskId
      }))
    })
      .then((value) => {
        if (isApiError(value.error)) {
          console.error(value.error.data)
          const msg = value.error.originalStatus === 400
            ? value.error.data
            : 'Unexpected Error, check logs'
          showSnackbar({
            isOpen: true,
            message: msg,
            status: 'error'
          })
        } else {
          showSnackbar({
            isOpen: true,
            message: 'Transactions Tagged',
            status: 'success'
          })
          closeModal()
          setSelected([])
        }
      })
      .catch((error) => {
        console.error(error)
        showSnackbar({
          isOpen: true,
          message: 'Failed to Post Capital Call',
          status: 'error'
        })
      })
  }

  return (
    authContext.hasBlotterAccess as boolean
      ? <Page>
          <Stack
            spacing={1}
            direction={'column'}
            width={'100%'}
            sx={{ paddingTop: '90px', paddingBottom: '10px', height: 'calc(100% - 90px - 10px)' }}
          >
            <Box sx={{ height: '15%' }}>
              <BlotterControlsPanel
                refreshFunction={() => { setRefreshSignal(!refreshSignal) }}
                viewType={viewType}
                setViewType={(option) => { navigate(`/blotter/${option}`) }}
                openModal={openModal}
                closeModal={closeModal}
                tagRequest={tagTransactions}
                showTag={
                  viewType === 'transactions' &&
                  selected.length > 0
                }
                time={time}
                setTime={setTime}
                addFilter={(piece: BlotterFilterPiece) => {
                  setFilters([...filters, piece])
                }}
                clearFilter={(type: BlotterFilterType) => {
                  setFilters(filters.filter((filter) => filter.type !== type))
                }}
                existingFilters={filters}
                untaggedOnly={untaggedOnly}
                setUntaggedOnly={setUntaggedOnly}
                includeFees={includeFees}
                setIncludeFees={setIncludeFees}
              />
            </Box>
            <Box sx={{ height: '85%' }}>
              <Routes>
                <Route path='' element={<Navigate to={REDIRECT_TYPE} replace />}/>
                <Route
                  path='transactions/*'
                  element={<TransactionsTable
                    time={time}
                    refreshSignal={refreshSignal}
                    selected={selected}
                    setSelected={setSelected}
                    filters={filters}
                    untaggedOnly={untaggedOnly}
                    includeFees={includeFees}
                  />}
                >
                  <Route path='new' element={<AddTransactionModal />} />
                  <Route path=':transactionId' element={<TransactionDetails />} />
                </Route>
                <Route
                  path='expenses/*'
                  element={<ExpensesTable
                    time={time}
                    refreshSignal={refreshSignal}
                  />}
                >
                  <Route path='new' element={<AddExpenseModal />} />
                  <Route path=':expenseId' element={<ExpenseDetails />} />
                </Route>
                <Route
                  path='capitalCalls/*'
                  element={<CapitalCallsTable
                    time={time}
                    refreshSignal={refreshSignal}
                  />}
                >
                  <Route path='new' element={<AddCapitalCallModal />} />
                  <Route path=':capitalCallId' element={<CapitalCallDetails />} />
                </Route>
                <Route
                  path='loans/*'
                  element={<LoansTable
                    time={time}
                    refreshSignal={refreshSignal}
                  />}
                >
                  <Route path='new' element={<AddLoanModal />} />
                  <Route path='close/:loanId' element={<CloseLoanModal />} />
                  <Route path=':loanId' element={<LoanDetails />} />
                </Route>
                <Route
                  path='unsettled/*'
                  element={<UnsettledTransactionsTable
                    time={time}
                    refreshSignal={refreshSignal}
                  />}
                >
                  <Route path='new' element={<AddTransactionModal />} />
                  <Route path='settle/:transactionId' element={<SettleTransactionModal />} />
                </Route>
                <Route path='*' element={<Navigate to={REDIRECT_TYPE} replace />}/>
              </Routes>
            </Box>
          </Stack>
          <Modal open={modalOpen} handleClose={closeModal}>
            {modalContent ?? <></>}
          </Modal>
        </Page>
      : <NoAccess />
  )
}

export default Blotter
