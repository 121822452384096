import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Box, Stack, useTheme } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import {
  type ColumnDefinition,
  Modal,
  ModalOpenButton,
  RefreshButton,
  useRequestSnackbar
} from '@r40cap/ui'
import {
  type AssetChange,
  type CapitalCall,
  type TransactionCreation,
  blotterApi
} from '@r40cap/pms-sdk'

import { capitalCallDetailsAssetChangeColumns } from './constants'
import type { AssetChangeRow, BlotterBigModal } from './types'
import type { BlotterInputType } from '../common/types'
import SmallLegTable from './SmallLegTable'
import CapitalCallPanel from './panels/CapitalCallPanel'
import { AddTransactionToCapitalCallModal } from './modals/AddTransactionToCapitalCallModal'
import { isApiError } from '../../../utils/errors'

function CapitalCallDetails (): React.JSX.Element {
  const { capitalCallId } = useParams()
  const navigate = useNavigate()
  const { palette } = useTheme()
  const { showSnackbar } = useRequestSnackbar()
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const [isForce, setIsForce] = useState<boolean>(false)
  const {
    data: assetChangesData,
    isFetching: isAssetChangesFetching,
    refetch: assetChangesRefetch
  } = blotterApi.useGetCapitalCallAssetChangesQuery({
    force: isForce,
    capitalCallId: capitalCallId ?? ''
  }, {
    skip: isInitialLoad
  })
  const {
    data: capitalCallData,
    isFetching: isCapitalCallFetching,
    refetch: capitalCallRefetch
  } = blotterApi.useGetCapitalCallByIdQuery({
    force: isForce,
    capitalCallId: capitalCallId ?? ''
  }, {
    skip: isInitialLoad
  })
  const [postTransaction] = blotterApi.useAddTransactionsMutation()

  const [capitalCall, setCapitalCall] = useState<CapitalCall | null>(null)

  useEffect(() => {
    if (isInitialLoad) {
      setIsInitialLoad(false)
    }
  }, [isInitialLoad])

  useEffect(() => {
    setCapitalCallResetSignal(!capitalCallResetSignal)
    if (capitalCallData !== undefined && capitalCallData !== null) {
      setCapitalCall(capitalCallData.data)
    } else {
      setCapitalCall(null)
    }
  }, [capitalCallData])

  const [assetChangesSetDataSignal, setAssetChangesSetDataSignal] = useState<boolean>(false)
  const [addModalOpen, setAddModalOpen] = useState<boolean>(false)
  const [capitalCallResetSignal, setCapitalCallResetSignal] = useState<boolean>(false)

  function refresh (): void {
    if (!isInitialLoad) {
      if (!isForce) {
        setIsForce(true)
      } else {
        refreshAssetChangesFunction()
        refreshExpenseFunction()
      }
    }
  }

  function refreshAssetChangesFunction (): void {
    assetChangesRefetch().then(() => {
      setAssetChangesSetDataSignal(!assetChangesSetDataSignal)
    }).catch(() => {
      console.error('Error Refreshing Asset Changes')
    })
  }

  function refreshExpenseFunction (): void {
    capitalCallRefetch().then(() => {
      setCapitalCallResetSignal(!capitalCallResetSignal)
    }).catch(() => {
      console.error('Error Refreshing Loan')
    })
  }

  function exitModal (): void {
    if ((Boolean(window.history.state)) && window.history.state.idx > 0) {
      navigate(-1)
    } else {
      navigate('/blotter/capitalCalls')
    }
  }

  function addTransaction (transaction: TransactionCreation): void {
    showSnackbar({
      isOpen: true,
      message: 'Pushing Transaction',
      status: 'processing'
    })
    postTransaction({ additions: [transaction] })
      .then((value) => {
        if (isApiError(value.error)) {
          console.error(value.error.data)
          const msg = value.error.originalStatus === 400
            ? value.error.data
            : 'Unexpected Error, check logs'
          showSnackbar({
            isOpen: true,
            message: msg,
            status: 'error'
          })
        } else {
          showSnackbar({
            isOpen: true,
            message: 'Transaction Added',
            status: 'success'
          })
          setAddModalOpen(false)
        }
      })
      .catch((error) => {
        console.error(error)
        showSnackbar({
          isOpen: true,
          message: 'Failed to post Transaction',
          status: 'error'
        })
      })
  }

  function openLinkedModalInner (relevantId: string, modalType: BlotterBigModal): void {
    if (modalType === 'transaction') {
      navigate(`/blotter/transactions/${relevantId}`)
    } else if (modalType === 'loan') {
      navigate(`/blotter/loans/${relevantId}`)
    } else if (modalType === 'expense') {
      navigate(`/blotter/expenses/${relevantId}`)
    }
  }

  return (
    <Modal
      open
      handleClose={exitModal}
    >
      <Box
        sx={{
          width: '95vw',
          height: '95vh',
          overflow: 'scroll'
        }}
      >
        <Stack
          direction={'column'}
          padding={'40px'}
          spacing={2}
        >
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <ModalOpenButton
              modalOpen={addModalOpen}
              setModalOpen={setAddModalOpen}
              modalContents={
                capitalCall !== null
                  ? <AddTransactionToCapitalCallModal capitalCall={capitalCall} submit={addTransaction}/>
                  : <></>
              }
            >
              <AddIcon />
            </ModalOpenButton>
            <RefreshButton
              refreshFunction={refresh}
              iconColor={palette.primary.main}
              buttonColor={palette.accent.main}
            />
          </Box>
          <CapitalCallPanel capitalCall={capitalCall} isFetching={isCapitalCallFetching} resetSignal={capitalCallResetSignal}/>
          <SmallLegTable<AssetChangeRow, AssetChange>
            title='Asset Changes'
            columns={capitalCallDetailsAssetChangeColumns}
            data={assetChangesData}
            isFetching={isAssetChangesFetching}
            openLinkedModal={
              (
                column: ColumnDefinition<AssetChangeRow, BlotterInputType, AssetChange, string, BlotterBigModal>,
                row: AssetChangeRow
              ) => {
                if (column.modalId !== undefined && column.modalType !== undefined) {
                  openLinkedModalInner(row[column.modalId], column.modalType)
                }
              }
            }
            translationFunciton={(change) => {
              return {
                ...change,
                settledTime: change.settledTime ?? '',
                account: `${change.account.platformName}: ${change.account.name}`,
                strategy: (
                  change.strategy === undefined || change.strategy === null ? '' : `${change.strategy.superStrategyName}: ${change.strategy.name}`
                ),
                desk: change.desk?.name ?? '',
                instrument: change.instrument.displayTicker,
                quantityDecimals: change.instrument.quantityDecimals,
                priceDecimals: change.instrument.priceDecimals,
                transactionId: change.associatedTransaction.id,
                investor: '',
                capitalCallId: '',
                expenseId: '',
                expenseReason: ''
              }
            }}
            setDataSignal={assetChangesSetDataSignal}
          />
        </Stack>
      </Box>
    </Modal>
  )
}

export default CapitalCallDetails
