import React, { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, Stack, Typography, useTheme } from '@mui/material'
import { Autocomplete, type AutocompleteOption, NumberInput } from '@r40cap/ui'
import { BetaDaysSelection, type Beta, marketDataApi } from '@r40cap/market-data-sdk'

import type { ShockEffect } from './types'

function ShockModalContent (props: {
  processShock: (effects: ShockEffect[]) => void
  closeModal: () => void
}): React.JSX.Element {
  const { processShock, closeModal } = props
  const { palette } = useTheme()
  const [betaDays, setBetaDays] = useState<number>(90)
  const [betas, setBetas] = useState<Beta[]>([])
  const [currencyOptions, setCurrencyOptions] = useState<ReadonlyArray<AutocompleteOption<string>>>([])
  const [currencyChoice, setCurrencyChoice] = useState<AutocompleteOption<string> | null>(null)

  const [shockAmountStr, setShockAmountStr] = useState<string>('')

  const { data, isFetching } = marketDataApi.useGetBetasQuery({})

  useEffect(() => {
    if (data !== null && data !== undefined) {
      setBetas(data.data)
      const allRefs = data.data.map((beta) => beta.referenceCurrencyId)
      const options = Array.from(new Set(allRefs)).map((ref) => {
        return {
          id: ref,
          label: ref
        }
      })
      setCurrencyOptions(options)
    }
  }, [data])

  const handleShock = (): void => {
    const usedBetas: Beta[] = betas.filter((beta) => beta.days === betaDays)
    if (usedBetas.length !== 0 && currencyChoice !== null) {
      const shockAmount = parseFloat(shockAmountStr)
      const shockAdj = shockAmount / 100
      if (shockAdj !== 0) {
        const effects: ShockEffect[] = usedBetas
          .filter((beta) => beta.referenceCurrencyId === currencyChoice.id)
          .map((beta) => {
            return {
              currencyId: beta.currencyId,
              priceFactor: 1 + shockAdj * beta.beta
            }
          })
        processShock(effects)
      }
      closeModal()
    }
  }

  return (
    <Box
      sx={{ width: '30vw' }}
    >
      {
        isFetching
          ? <Box
            padding={4}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <CircularProgress sx={{ color: palette.accent.main }}/>
          </Box>
          : (<Stack direction={'column'} spacing={2} padding={3}>
              <BetaDaysSelection
                betaDays={betaDays}
                setBetaDays={setBetaDays}
              />
              <Stack direction='row' spacing={1}>
                <Autocomplete<string>
                  trySetObject={() => {}}
                  options={currencyOptions}
                  choice={currencyChoice}
                  setChoice={setCurrencyChoice}
                  label='Currency'
                  activeColor={palette.accent.main}
                  inactiveColor={palette.tableBodyText.main}
                />
                <NumberInput
                  title={'Shock Size'}
                  intOnly={false}
                  valueString={shockAmountStr}
                  setValueString={setShockAmountStr}
                  submit={(value: number) => { handleShock() }}
                  color={palette.accent.main}
                />
                <Typography
                  fontSize={'1.5rem'}
                  sx={{
                    color: palette.tableBodyText.main,
                    alignSelf: 'center'
                  }}
                >
                  %
                </Typography>
              </Stack>
              <Button
                variant='contained'
                onClick={handleShock}
                sx={{
                  width: '50%',
                  alignSelf: 'center',
                  backgroundColor: palette.accent.main,
                  color: palette.background.default
                }}
              >
                Submit
              </Button>
            </Stack>)
      }
    </Box>
  )
}

export default ShockModalContent
