export const lightPalette = {
  primary: {
    main: '#C9D6DF'
  },
  secondary: {
    main: '#92A1AB'
  },
  tertiary: {
    main: '#B9C6CF'
  },
  background: {
    default: '#030303',
    paper: '#F0F5F9'
  },
  themeToggleBackground: {
    main: '#0E1111'
  },
  themeToggleIcon: {
    main: '#F6F1D5'
  },
  accent: {
    main: '#EB4511'
  },
  tableHeaderText: {
    main: '#CFCFCF'
  },
  tableBodyText: {
    main: '#040404'
  },
  logoColor: {
    main: '#030303'
  },
  staleColor: {
    main: '#F57D0C'
  },
  goodHealthColor: {
    main: '#14471E'
  },
  dangerHealthColor: {
    main: '#EE9B01'
  },
  badHealthColor: {
    main: '#AA1803'
  }
}
