import React, { useMemo, useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import type { Dayjs } from 'dayjs'
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from '@mui/material'
import {
  HeaderCell,
  type Order,
  ValueCell,
  stableSort,
  getComparator,
  TableSkeleton,
  PaginationFooter
} from '@r40cap/ui'
import { blotterApi } from '@r40cap/pms-sdk'

import { type ExpenseRow } from '../common/types'
import type { PaginationState } from '../../common/types'
import { expenseColumns, ROWS_PER_PAGE } from './constants'

interface ExpensesTableHeadProps {
  onRequestSort: (property: keyof ExpenseRow) => void
  order: Order
  orderBy: keyof ExpenseRow
}

function ExpensesTableHead (props: ExpensesTableHeadProps): React.JSX.Element {
  const {
    onRequestSort,
    order,
    orderBy
  } = props
  const { palette } = useTheme()

  return (
    <TableHead>
      <TableRow>
        {expenseColumns.map((column, idx) => (
          <HeaderCell<ExpenseRow, any>
            column={column}
            isActiveSort={orderBy === column.id}
            order={order}
            sortHandler={onRequestSort}
            key={idx}
            backgroundColor={palette.background.default}
            textColor={palette.tableHeaderText.main}
            activeTextColor={palette.accent.main}
          />
        ))}
        <TableCell/>
      </TableRow>
    </TableHead>
  )
}

function ExpensesTableBody (props: {
  rows: ExpenseRow[]
  paginationState: PaginationState
}): React.JSX.Element {
  const navigate = useNavigate()
  const { palette } = useTheme()
  const { rows, paginationState } = props

  const visibleRows = rows.slice(
    (paginationState.pageNumber - 1) * paginationState.pageSize,
    paginationState.pageNumber * paginationState.pageSize
  )

  return <TableBody>
    {
      visibleRows.map((row, index) => {
        return (
          <TableRow
            key={index}
            sx={{
              cursor: 'pointer',
              backgroundColor:
                index % 2 === 0
                  ? palette.primary.main
                  : palette.tertiary.main
            }}
          >
            {expenseColumns.map((column, idx) => (
              <ValueCell<ExpenseRow, any>
                column={column}
                item={row}
                defaultTextColor={palette.tableBodyText.main}
                redTextColor='red'
                greenTextColor='green'
                key={idx}
              />
            ))}
            <TableCell align='center'>
              <Button
                sx={{
                  outlineColor: palette.accent.main,
                  color: palette.accent.main,
                  width: '50%',
                  alignSelf: 'center'
                }}
                variant='outlined'
                onClick={() => { navigate(`/blotter/expenses/${row.id}`) }}
              >
                Details
              </Button>
            </TableCell>
          </TableRow>
        )
      })
    }
    {
      visibleRows.length === 0
        ? <TableRow sx={{ height: '100%' }}>
            <TableCell
              colSpan={expenseColumns.length}
              sx={{ justifyItems: 'center', alignItems: 'center' }}
            >
              <Typography textAlign={'center'}>No Data</Typography>
            </TableCell>
          </TableRow>
        : <TableRow sx={{ height: '100%' }} />
    }
  </TableBody>
}

function ExpensesTable (props: {
  time: Dayjs | null
  refreshSignal: boolean
}): React.JSX.Element {
  const { time, refreshSignal } = props
  const { palette } = useTheme()
  const [rows, setRows] = useState<ExpenseRow[]>([])
  const [order, setOrder] = useState<Order>('desc')
  const [orderBy, setOrderBy] = useState<keyof ExpenseRow>('time')
  const [currentPage, setCurrentPage] = useState<number>(1)
  const { data, refetch, isFetching } = blotterApi.useGetRecentExpensesQuery({
    time: time?.format('YYYY-MM-DD HH:mm') ?? undefined
  })

  useEffect(() => {
    refetch().catch((error) => { console.error(error) })
  }, [refreshSignal])

  useEffect(() => {
    if (data !== null && data !== undefined) {
      setRows(data.data.map((expense) => {
        return {
          id: expense.id,
          time: expense.time,
          vendor: expense.vendor.name,
          reason: expense.reason
        }
      }))
    }
  }, [data])

  const visibleRows = useMemo(
    () => stableSort(rows, getComparator(order, orderBy)),
    [order, orderBy, rows]
  )

  const handleRequestSort = (property: keyof ExpenseRow): void => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  function pageBackward (): void {
    setCurrentPage(Math.max(currentPage - 1, 1))
  }

  function skipBackward (): void {
    setCurrentPage(1)
  }

  function pageForward (): void {
    const maxPages = Math.ceil(rows.length / (ROWS_PER_PAGE ?? 10))
    setCurrentPage(Math.min(currentPage + 1, maxPages))
  }

  function skipForward (): void {
    const maxPages = Math.ceil(rows.length / (ROWS_PER_PAGE ?? 10))
    setCurrentPage(maxPages)
  }

  useEffect(() => {
    setCurrentPage(1)
  }, [rows.length])

  return (
    <>
      <TableContainer
        sx={{
          backgroundColor: palette.primary.main,
          borderRadius: '5px',
          height: '100%'
        }}
      >
        <Table
          stickyHeader
          sx={{
            tableLayout: 'fixed',
            height: '100%'
          }}
        >
          <ExpensesTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          {
            isFetching as boolean
              ? <TableSkeleton
                numRows={10}
                columns={expenseColumns}
              />
              : <ExpensesTableBody
                  rows={visibleRows}
                  paginationState={{ pageNumber: currentPage, pageSize: ROWS_PER_PAGE }}
                />
          }
          {
            !isFetching && <PaginationFooter
              numRows={rows.length}
              currentPage={currentPage}
              rowsPerPage={ROWS_PER_PAGE}
              onPageBackward={pageBackward}
              onSkipBackward={skipBackward}
              onPageForward={pageForward}
              onSkipForward={skipForward}
              numColumns={expenseColumns.length + 1}
              backgroundColor={palette.background.default}
              textColor={palette.tableHeaderText.main}
              isDense
            />
          }
        </Table>
      </TableContainer>
      <Outlet />
    </>
  )
}

export default ExpensesTable
