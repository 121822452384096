import React from 'react'

import { InputType } from './types'
import { NumberEditContent } from './EditModals'

export function getModalContent<O> (
  inputType: InputType,
  label: string,
  editProperty: keyof O,
  closeModal: () => void,
  handleSubmission: (value: any, selectedIds: readonly string[], property?: keyof O) => void,
  newSelected: readonly string[],
  setSelected: (values: string[]) => void,
  initialValue?: string | null
): React.JSX.Element {
  let content: React.JSX.Element = <></>

  function submit (value: any): void {
    handleSubmission(value, newSelected, editProperty)
    setSelected([])
  }

  switch (inputType) {
    case InputType.FLOAT:
      content = <NumberEditContent
        closeModal={closeModal}
        submit={submit}
        title={label}
      />
      break
    default:
      content = <NumberEditContent
        closeModal={closeModal}
        submit={submit}
        title={label}
      />
  }
  return content
}
