import React, {
  useEffect,
  useMemo,
  useState,
  type MouseEvent
} from 'react'
import {
  Box,
  Button,
  Checkbox,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme
} from '@mui/material'
import {
  type Order,
  getComparator,
  stableSort,
  HeaderCell,
  TableSkeleton,
  ValueCell,
  useRequestSnackbar
} from '@r40cap/ui'
import {
  type AccountBalance,
  type BalanceQueryResult,
  type Instrument,
  InstrumentCreator,
  glossaryApi
} from '@r40cap/pms-sdk'

import { updatingColumns, unmatchedColumns } from './constants'
import type { UnmatchedRow, UpdatingRow } from './types'
import { getEditModalContent } from './utils'
import { isApiError } from '../../../utils/errors'

interface UpdatingTableHeadProps {
  onRequestSort: (property: keyof UpdatingRow) => void
  order: Order
  orderBy: keyof UpdatingRow
  numSelected: number
  rowCount: number
  onSelectAllClick: () => void
}

function UpdatingTableHead (props: UpdatingTableHeadProps): React.JSX.Element {
  const {
    onRequestSort,
    order,
    orderBy,
    numSelected,
    rowCount,
    onSelectAllClick
  } = props
  const { palette } = useTheme()

  return (
    <TableHead>
      <TableRow>
        <TableCell
          align='center'
          padding="checkbox"
          sx={{ backgroundColor: palette.background.default }}
        >
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onClick={(event: MouseEvent<unknown>) => { onSelectAllClick() }}
            style={{ color: palette.accent.main }}
            size="small"
          />
        </TableCell>
        {updatingColumns.map((column, idx) => (
          <HeaderCell<UpdatingRow, any>
            column={column}
            isActiveSort={orderBy === column.id}
            order={order}
            sortHandler={onRequestSort}
            key={idx}
            backgroundColor={palette.background.default}
            textColor={palette.tableHeaderText.main}
            activeTextColor={palette.accent.main}
          />
        ))}
      </TableRow>
    </TableHead>
  )
}

function UpdatingTableBody (props: {
  rows: readonly UpdatingRow[]
  order: Order
  orderBy: keyof UpdatingRow
  openModal: (content: React.JSX.Element) => void
  closeModal: () => void
  isSelected: (rowId: string) => boolean
  clickHandle: (event: MouseEvent<unknown>, id: string) => void
  submitEdit: (itemId: string, property: keyof UpdatingRow, value: any) => void
}): React.JSX.Element {
  const { palette } = useTheme()
  const {
    rows,
    order,
    orderBy,
    openModal,
    closeModal,
    clickHandle,
    isSelected,
    submitEdit
  } = props

  const visibleRows = useMemo(
    () => stableSort(rows, getComparator(order, orderBy)),
    [order, orderBy, rows]
  )

  return <TableBody>
    {
      visibleRows.map((row, index) => {
        const isItemSelected = isSelected(row.instrumentId)
        const labelId = `txn-table-checkbox-${index}`

        return (
          <TableRow
            key={index}
            sx={{
              cursor: 'pointer',
              backgroundColor:
                index % 2 === 0
                  ? palette.primary.main
                  : palette.tertiary.main
            }}
          >
            <TableCell padding='checkbox' align='center'>
              <Checkbox
                style={{ color: palette.accent.main }}
                size="small"
                onClick={(event) => { clickHandle(event, row.instrumentId) }}
                checked={isItemSelected}
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </TableCell>
            {updatingColumns.map((column, colIdx) => (
              <ValueCell<UpdatingRow, any>
                column={column}
                item={row}
                key={colIdx}
                defaultTextColor={palette.tableBodyText.main}
                redTextColor='red'
                greenTextColor='green'
                onDoubleClick={
                  () => {
                    if (column.inputType !== undefined && column.editId !== undefined) {
                      const content = getEditModalContent(
                        column.inputType,
                        column.label,
                        closeModal,
                        (value: any) => {
                          if (column.editId !== undefined) {
                            submitEdit(row.instrumentId, column.editId, value)
                          }
                        },
                        row[column.id]
                      )
                      openModal(content)
                    }
                  }
                }
              />
            ))}
          </TableRow>
        )
      })
    }
  </TableBody>
}

interface UnmatchedTableHeadProps {
  onRequestSort: (property: keyof UnmatchedRow) => void
  order: Order
  orderBy: keyof UnmatchedRow
}

function UnmatchedTableHead (props: UnmatchedTableHeadProps): React.JSX.Element {
  const { palette } = useTheme()
  const {
    onRequestSort,
    order,
    orderBy
  } = props

  return (
    <TableHead>
      <TableRow>
        <TableCell/>
        {unmatchedColumns.map((column, idx) => (
          <HeaderCell<UnmatchedRow, any>
            column={column}
            isActiveSort={orderBy === column.id}
            order={order}
            sortHandler={onRequestSort}
            key={idx}
            backgroundColor={palette.background.default}
            textColor={palette.tableHeaderText.main}
            activeTextColor={palette.accent.main}
          />
        ))}
      </TableRow>
    </TableHead>
  )
}

function UnmatchedTableBody (props: {
  rows: readonly UnmatchedRow[]
  order: Order
  orderBy: keyof UnmatchedRow
  openModal: (content: React.JSX.Element) => void
  closeModal: () => void
}): React.JSX.Element {
  const { showSnackbar } = useRequestSnackbar()
  const { palette } = useTheme()
  const {
    rows,
    order,
    orderBy,
    openModal,
    closeModal
  } = props

  const [postAdditionsMutation] = glossaryApi.useAddInstrumentsMutation()

  const visibleRows = useMemo(
    () => stableSort(rows, getComparator(order, orderBy)),
    [order, orderBy, rows]
  )

  function handleInstrumentSubmit (newInstrument: Instrument): void {
    showSnackbar({
      isOpen: true,
      message: 'Adding Instrument',
      status: 'processing'
    })
    postAdditionsMutation({ additions: [newInstrument] })
      .then((value) => {
        if (isApiError(value.error)) {
          console.error(value.error.data)
          const msg = value.error.originalStatus === 400
            ? value.error.data
            : 'Unexpected Error, check logs'
          showSnackbar({
            isOpen: true,
            message: msg,
            status: 'error'
          })
        } else {
          showSnackbar({
            isOpen: true,
            message: 'Created Instrument',
            status: 'success'
          })
          closeModal()
        }
      })
      .catch((error): void => {
        showSnackbar({
          isOpen: true,
          message: 'Error in Instrument creation',
          status: 'failure'
        })
        console.error(error)
      })
  }

  return <TableBody>
    {
      visibleRows.map((row, index) => {
        return (
          <TableRow
            key={index}
            sx={{
              cursor: 'pointer',
              backgroundColor:
                index % 2 === 0
                  ? palette.primary.main
                  : palette.tertiary.main
            }}
          >
            <TableCell>
              <Button
                onClick={() => {
                  openModal(<Box
                      sx={{
                        width: '30vw',
                        padding: '40px'
                      }}
                    >
                      <InstrumentCreator
                        mainColor={palette.accent.main}
                        secondaryColor={palette.tableBodyText.main}
                        callColor={palette.success.main}
                        putColor={palette.error.main}
                        submitFunction={handleInstrumentSubmit}
                        enhanced={true}
                        prefillName={row.ticker}
                      />
                    </Box>)
                }}
                sx={{
                  outlineColor: palette.accent.main,
                  color: palette.accent.main,
                  alignItems: 'center'
                }}
                variant='outlined'
              >Add</Button>
            </TableCell>
            {unmatchedColumns.map((column, colIdx) => (
              <ValueCell<UnmatchedRow, any>
                column={column}
                item={row}
                key={colIdx}
                defaultTextColor={palette.tableBodyText.main}
                redTextColor='red'
                greenTextColor='green'
              />
            ))}
          </TableRow>
        )
      })
    }
  </TableBody>
}

function UpdatingViewTable (props: {
  refreshSignal: boolean
  platformData: BalanceQueryResult | null
  platformIsFetching: boolean
  systemData: AccountBalance[] | null
  systemIsFetching: boolean
  openModal: (content: React.JSX.Element) => void
  closeModal: () => void
  selectedIds: readonly string[]
  setSelectedIds: (value: string[]) => void
  setMap: (value: Map<string, UpdatingRow>) => void
  map: Map<string, UpdatingRow>
}): React.JSX.Element {
  const {
    openModal,
    closeModal,
    refreshSignal,
    selectedIds,
    setSelectedIds,
    platformData,
    platformIsFetching,
    systemData,
    systemIsFetching,
    map,
    setMap
  } = props
  const { palette } = useTheme()
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<keyof UpdatingRow>('instrument')
  const [unmatchedOrder, setUnmatchedOrder] = useState<Order>('asc')
  const [unmatchedOrderBy, setUnmatchedOrderBy] = useState<keyof UnmatchedRow>('ticker')
  const [unmatchedRows, setUnmatchedRows] = useState<readonly UnmatchedRow[]>([])

  const handleRequestSort = (property: keyof UpdatingRow): void => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleUnmatchedRequestSort = (property: keyof UnmatchedRow): void => {
    const isAsc = unmatchedOrderBy === property && unmatchedOrder === 'asc'
    setUnmatchedOrder(isAsc ? 'desc' : 'asc')
    setUnmatchedOrderBy(property)
  }

  useEffect(() => {
    if (
      (platformData !== undefined && platformData !== null) ||
      (systemData !== undefined && systemData !== null)
    ) {
      const systemList = systemData ?? []
      const platformList = platformData?.coveredResults ?? []
      const balanceMap = new Map<string, UpdatingRow>()
      systemList.forEach((item) => {
        const row: UpdatingRow = {
          instrumentId: item.instrumentId,
          instrument: item.instrumentDisplayTicker,
          ourBalance: item.balance,
          platformBalance: 0,
          platformErrorExplanation: '',
          adminBalance: 0,
          adminErrorExplanation: '',
          balanceDecimals: item.instrumentQuantityDecimals
        }
        balanceMap.set(item.instrumentId, row)
      })
      platformList.forEach((item) => {
        const currentRow = balanceMap.get(item.instrument.id)
        if (currentRow !== undefined) {
          const newRow: UpdatingRow = {
            ...currentRow,
            platformBalance: item.balance
          }
          balanceMap.set(item.instrument.id, newRow)
        } else {
          const row: UpdatingRow = {
            instrumentId: item.instrument.id,
            instrument: item.instrument.displayTicker,
            ourBalance: 0,
            platformBalance: item.balance,
            platformErrorExplanation: '',
            adminBalance: 0,
            adminErrorExplanation: '',
            balanceDecimals: item.instrument.quantityDecimals
          }
          balanceMap.set(item.instrument.id, row)
        }
      })
      setMap(balanceMap)
    }
    if (platformData !== undefined && platformData !== null) {
      const newUnmatched: UnmatchedRow[] = platformData.nonCoveredResults.map((result) => {
        const row: UnmatchedRow = {
          ticker: result.platformTicker,
          platformBalance: result.balance
        }
        return row
      })
      setUnmatchedRows(newUnmatched)
    }
    setSelectedIds([])
  }, [platformData, systemData, refreshSignal])

  const handleClick = (event: MouseEvent<unknown>, id: string): void => {
    const selectedIndex = selectedIds.indexOf(id)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedIds, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedIds.slice(1))
    } else if (selectedIndex === selectedIds.length - 1) {
      newSelected = newSelected.concat(selectedIds.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedIds.slice(0, selectedIndex),
        selectedIds.slice(selectedIndex + 1)
      )
    }
    setSelectedIds(newSelected)
  }

  const handleSelectAllClick = (): void => {
    const logs = Array.from(map.values())
    if (selectedIds.length < logs.length) {
      const newSelected = logs.map((n) => n.instrumentId)
      setSelectedIds(newSelected)
    } else {
      setSelectedIds([])
    }
  }

  function handleEdit (instrumentId: string, property: keyof UpdatingRow, value: any): void {
    const currentRow = map.get(instrumentId)
    if (currentRow !== undefined) {
      const newMap = map
      const newRow = {
        ...currentRow,
        [property]: value
      }
      newMap.set(instrumentId, newRow)
      setMap(newMap)
    }
  }

  const isSelected = (id: string): boolean => selectedIds.includes(id)

  return (
    <Stack direction={'column'} sx={{ height: '75vh', overflow: 'scroll' }}>
      <TableContainer
        sx={{
          backgroundColor: palette.primary.main,
          borderRadius: '5px',
          height: '55vh',
          minHeight: '55vh'
        }}
      >
        <Table stickyHeader sx={{ tableLayout: 'fixed' }}>
          <UpdatingTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            numSelected={selectedIds.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={Array.from(map.values()).length}
          />
          {
            (
              (
                !(systemIsFetching as boolean) &&
                systemData !== null &&
                systemData !== undefined
              ) ||
              (
                !(platformIsFetching as boolean) &&
                platformData !== null &&
                platformData !== undefined
              )
            )
              ? <UpdatingTableBody
                  rows={Array.from(map.values())}
                  order={order}
                  orderBy={orderBy}
                  openModal={openModal}
                  closeModal={closeModal}
                  submitEdit={handleEdit}
                  clickHandle={handleClick}
                  isSelected={isSelected}
                />
              : <TableSkeleton
                  numRows={10}
                  columns={updatingColumns}
                />
          }
        </Table>
      </TableContainer>
      {
        (
          !(platformIsFetching as boolean) &&
          platformData !== undefined &&
          platformData !== null &&
          platformData.nonCoveredResults.length > 0
        ) && <Stack sx={{ width: '70%' }}>
            <h1>Unmatched</h1>
            <TableContainer
              sx={{
                backgroundColor: palette.primary.main,
                borderRadius: '5px',
                height: '55vh',
                minHeight: '55vh'
              }}
            >
              <Table stickyHeader sx={{ tableLayout: 'fixed' }}>
                <UnmatchedTableHead
                  onRequestSort={handleUnmatchedRequestSort}
                  order={unmatchedOrder}
                  orderBy={unmatchedOrderBy}
                />
                {
                  platformIsFetching as boolean || platformData === undefined || platformData === null
                    ? <TableSkeleton
                        numRows={10}
                        columns={updatingColumns}
                      />
                    : <UnmatchedTableBody
                        rows={unmatchedRows}
                        order={unmatchedOrder}
                        orderBy={unmatchedOrderBy}
                        openModal={openModal}
                        closeModal={closeModal}
                      />
                }
              </Table>
            </TableContainer>
          </Stack>
      }
    </Stack>
  )
}

export default UpdatingViewTable
