import React from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule'
import AllInclusiveIcon from '@mui/icons-material/AllInclusive'

function HealthScoreDisplay (props: {
  score: number | null
  isFetching: boolean
  isEdited: boolean
}): React.JSX.Element {
  const {
    score,
    isFetching,
    isEdited
  } = props
  const { palette } = useTheme()

  const textColor = score === null || isFetching
    ? palette.tableBodyText.main
    : score >= 3.5
      ? palette.goodHealthColor.main
      : score >= 1.1
        ? palette.dangerHealthColor.main
        : palette.badHealthColor.main

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: palette.tertiary.main,
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        borderColor: isEdited ? palette.accent.main : undefined,
        borderWidth: isEdited ? '1px' : undefined,
        borderStyle: isEdited ? 'solid' : undefined
      }}
    >
      {
        score === null || isFetching
          ? <HorizontalRuleIcon
              sx={{
                fontSize: '3.5rem',
                color: textColor,
                alignContent: 'center'
              }}
            />
          : score === Infinity
            ? <AllInclusiveIcon
                sx={{
                  fontSize: '3.5rem',
                  color: textColor,
                  alignContent: 'center'
                }}
              />
            : (
              <Box sx={{ textAlign: 'center', position: 'relative' }}>
                <Typography
                  sx={{
                    fontSize: '3.5rem',
                    fontWeight: 'bold',
                    color: textColor
                  }}
                >
                  {score.toLocaleString('en-US', { maximumFractionDigits: 5 })}
                </Typography>
              </Box>
              )
      }
    </Box>
  )
}

export default HealthScoreDisplay
