import React, { useEffect, useState } from 'react'
import type { Dayjs } from 'dayjs'
import {
  Table,
  TableContainer,
  useTheme
} from '@mui/material'
import { type Order } from '@r40cap/ui'
import {
  type Price,
  portfolioApi
} from '@r40cap/pms-sdk'

import PositionsByAccountTableBody from './PositionsByAccountTableBody'
import { accountColumns } from './constants'
import {
  OneLevelTableHead,
  PositionsTableFooter
} from './rows'
import type {
  PortfolioRow,
  BalanceWithPrice
} from './types'

function UnsettledByAccountTable (props: {
  time?: Dayjs
  referencePrice?: Price
  refreshSignal: boolean
  pxData?: Price[]
  pxIsLoading: boolean
}): React.JSX.Element {
  const { palette } = useTheme()
  const {
    time,
    refreshSignal,
    referencePrice,
    pxData,
    pxIsLoading
  } = props

  const [openedAccounts, setOpenedAccounts] = useState<readonly string[]>([])
  const [order, setOrder] = useState<Order>('desc')
  const [orderBy, setOrderBy] = useState<keyof PortfolioRow>('marketValue')
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const [queryParams, setQueryParams] = useState({
    time: time?.format('YYYY-MM-DD HH:mm') ?? undefined,
    force: false
  })
  const [combinedData, setCombinedData] = useState<BalanceWithPrice[]>([])

  const { data: balData, refetch: balRefetch, isFetching: balIsFetching } = portfolioApi.useGetUnsettledByAccountQuery(queryParams, {
    skip: isInitialLoad
  })

  useEffect(() => {
    if (isInitialLoad) {
      setIsInitialLoad(false)
    }
  }, [isInitialLoad])

  useEffect(() => {
    setQueryParams({
      time: time?.format('YYYY-MM-DD HH:mm') ?? undefined,
      force: false
    })
  }, [time])

  useEffect(() => {
    if (!isInitialLoad) {
      if (!queryParams.force) {
        setQueryParams((prevParams) => ({
          ...prevParams,
          force: true
        }))
      } else {
        balRefetch().catch(() => {
          console.error('Error Refreshing')
        })
      }
    }
  }, [refreshSignal])

  useEffect(() => {
    if (balData !== null && balData !== undefined) {
      const combined = balData.data.map(bal => {
        const effectivePxData = pxIsLoading ? [] : pxData ?? []
        const priceData = effectivePxData.find(px => px.instrument.id === bal.instrumentId)
        return {
          ...bal,
          price: priceData?.price,
          beta: priceData?.beta,
          delta: priceData?.delta,
          priceTime: priceData?.time
        }
      })
      setCombinedData(combined)
    }
  }, [balData, pxData])

  const handleRequestSort = (property: keyof PortfolioRow): void => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const columnsToUse = referencePrice !== undefined
    ? accountColumns.map((column) => {
      if (column.id === 'referenceMarketValue') {
        const labelToUse = `MV (${referencePrice.instrument.displayTicker})`
        const decToUse = referencePrice.instrument.quantityDecimals
        const valDetsToUse = {
          ...column.valueDetails,
          suffix: `\u00A0${referencePrice.instrument.displayTicker}`
        }
        return {
          ...column,
          label: labelToUse,
          decimals: decToUse,
          valueDetails: valDetsToUse
        }
      } else {
        return column
      }
    })
    : accountColumns

  return (
    <TableContainer
      sx={{
        backgroundColor: palette.primary.main,
        height: '100%',
        borderRadius: '5px'
      }}
    >
      <Table stickyHeader sx={{ height: '100%' }}>
        <OneLevelTableHead
          onRequestSort={handleRequestSort}
          order={order}
          orderBy={orderBy}
          columns={columnsToUse}
          closeAll={() => {
            setOpenedAccounts([])
          }}
          openAll={() => {
            setOpenedAccounts(Array.from(new Set(combinedData.map((a) => a.accountId))))
          }}
          openDesc='Accounts'
          isLoading={balIsFetching}
        />
        <PositionsByAccountTableBody
          isLoading={balIsFetching}
          positions={combinedData}
          order={order}
          orderBy={orderBy}
          columns={columnsToUse}
          referencePrice={referencePrice}
          includeSmallAmounts
          smallThreshold={0}
          requestedTime={time}
          openedAccounts={openedAccounts}
          toggleAccount={(accountId) => {
            if (openedAccounts.includes(accountId)) {
              setOpenedAccounts(openedAccounts.filter((a) => a !== accountId))
            } else {
              setOpenedAccounts([...openedAccounts, accountId])
            }
          }}
        />
        <PositionsTableFooter
          isLoading={balIsFetching}
          totalObject={{
            name: 'Fund Total',
            marketValue: combinedData.reduce((partialSum, a) => partialSum + a.multiplier * (a.price ?? 0) * a.quantity, 0) ?? 0,
            referenceMarketValue: (referencePrice !== undefined)
              ? combinedData.reduce((partialSum, a) => partialSum + a.multiplier * (a.price ?? 0) * a.quantity, 0) / referencePrice.price
              : 0,
            quantityDecimals: 2,
            priceDecimals: 2
          }}
          columns={columnsToUse}
        />
      </Table>
    </TableContainer>
  )
}

export default UnsettledByAccountTable
