import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import dayjs, { type Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Stack,
  Typography,
  useTheme
} from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import AddIcon from '@mui/icons-material/Add'
import {
  EditableTable,
  Modal,
  ModalOpenButton,
  StringInput,
  useRequestSnackbar
} from '@r40cap/ui'
import {
  type LiteTrader,
  TraderInput,
  type TransactionAssetChange,
  type TransactionCreation,
  TransactionAssetChangeCreator,
  type LiteInstrument,
  type LiteAccount,
  type LiteStrategy,
  AccountInput,
  StrategyInput,
  type LiteDesk,
  DeskInput,
  type Price,
  blotterApi,
  priceApi
} from '@r40cap/pms-sdk'

import { transactionSelectOptions } from './constants'
import type { TransactionAdditionSelectOption } from './types'
import { getBlotterEditModalContent } from '../utils'
import type {
  AddAssetChangeRow,
  BlotterInputType
} from '../common/types'
import { transactionAssetChangeColumns } from '../common/constants'
import LegInput from '../common/BlotterInputs/LegInput'
import { isApiError } from '../../../utils/errors'

function Custom (props: {
  submit: (transaction: TransactionCreation) => void
}): React.JSX.Element {
  const { submit } = props
  dayjs.extend(utc)
  const { palette } = useTheme()
  const [trader, setTrader] = useState<LiteTrader | null>(null)
  const [expectedSettlementTime, setExpectedSettlementTime] = useState<Dayjs | null>(null)
  const [comments, setComments] = useState<string>('')
  const [assetChanges, setAssetChanges] = useState<readonly TransactionAssetChange[]>([])

  const [assetChangeAddModalOpen, setAssetChangeAddModalOpen] = useState<boolean>(false)
  const [selectedAssetChangeIds, setSelectedAssetChangeIds] = useState<readonly string[]>([])

  const handleChangeExpectedSettlementTime = (newTime: Dayjs | null): void => {
    if (newTime !== null) {
      setExpectedSettlementTime(newTime)
    }
  }

  function appendAssetChange (assetChange: TransactionAssetChange): void {
    setAssetChanges(assetChanges.concat(assetChange))
    setAssetChangeAddModalOpen(false)
  }

  const getAssetChangePreset = (rowId: string, property: keyof TransactionAssetChange): any => {
    const relevantObject = assetChanges.find((dataRow: TransactionAssetChange) => dataRow.id === rowId)
    if (relevantObject === undefined) {
      return undefined
    } else {
      return relevantObject[property]
    }
  }

  const handleAssetChangeEdit = (property: keyof TransactionAssetChange, value: any, selected: readonly string[]): void => {
    const updatedData = assetChanges.map(item =>
      selected.includes(item.id) ? { ...item, [property]: value } : item
    )
    setAssetChanges(updatedData)
  }

  function handleSubmission (): void {
    const id = uuidv4()
    const txn: TransactionCreation = {
      id: `new-${id}`,
      trader: trader ?? undefined,
      expectedSettlementTime: expectedSettlementTime !== null && expectedSettlementTime !== undefined ? expectedSettlementTime.format('YYYY-MM-DD HH:mm') : undefined,
      comments,
      entryPlatform: 'Frontend',
      entryPlatformId: `FE-${id}`,
      assetChanges: assetChanges.map((value) => value),
      openedLiabilities: [],
      closedLiabilities: []
    }
    submit(txn)
  }

  return (
    <Stack direction={'column'} spacing={1}>
      <Stack direction={'row'} sx={{ width: '100%' }}>
        <Box sx={{ width: '50%' }}>
          <TraderInput
            setTrader={setTrader}
            setNone={(dummy: boolean) => {}}
            allowNone={false}
            mainColor={palette.accent.main}
            secondaryColor={palette.tableBodyText.main}
            enhanced
          />
        </Box>
        <Box sx={{ width: '50%' }}>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Box sx={{ width: '50%' }}>
              <Typography
                sx={{ color: palette.tableBodyText.main }}
              >
                Expected Settlement Time
              </Typography>
            </Box>
            <Box sx={{ width: '50%' }}>
              <DateTimePicker
                value={expectedSettlementTime}
                format='YYYY-MM-DD HH:mm'
                onChange={handleChangeExpectedSettlementTime}
                closeOnSelect={false}
                ampm={false}
                timeSteps={{ hours: 1, minutes: 1 }}
              />
            </Box>
          </Stack>
        </Box>
      </Stack>
      <StringInput
        value={comments}
        setValue={setComments}
        title='Comments'
        color={palette.accent.main}
      />
      <Box sx={{ width: '100%' }} textAlign={'right'}>
        <ModalOpenButton
          modalOpen={assetChangeAddModalOpen}
          setModalOpen={setAssetChangeAddModalOpen}
          modalContents={<Box
            sx={{
              width: '40vw',
              padding: '40px'
            }}
          >
            <TransactionAssetChangeCreator
              mainColor={palette.accent.main}
              secondaryColor={palette.tableBodyText.main}
              callColor={palette.success.main}
              putColor={palette.error.main}
              submitFunction={appendAssetChange}
              includeBaseFxFilter
              includeEntityFilter
              includeInstrumentTypeFilter
              includeSuperStrategyFilter
              enhanced
            />
          </Box>}
        >
          <AddIcon />
        </ModalOpenButton>
      </Box>
      <Box
        sx={{ height: '40vh' }}
      >
        <EditableTable<AddAssetChangeRow, TransactionAssetChange, BlotterInputType, string>
          rows={assetChanges.map((assetChange) => {
            return {
              id: assetChange.id,
              time: assetChange.time,
              settledTime: assetChange.settledTime ?? '',
              account: `${assetChange.account.platform.name} - ${assetChange.account.name}`,
              strategy: (
                assetChange.strategy === undefined || assetChange.strategy === null
                  ? ''
                  : `${assetChange.strategy.superStrategy.name} - ${assetChange.strategy.name}`
              ),
              desk: assetChange.desk?.name ?? '',
              instrument: assetChange.instrument.displayTicker,
              quantity: assetChange.quantity,
              price: assetChange.price,
              isFee: assetChange.isFee,
              quantityDecimals: assetChange.instrument.quantityDecimals,
              priceDecimals: assetChange.instrument.priceDecimals
            }
          })}
          columns={transactionAssetChangeColumns}
          selected={selectedAssetChangeIds}
          setSelected={setSelectedAssetChangeIds}
          defaultOrderBy={'instrument'}
          defaultOrder={'desc'}
          isFetching={false}
          getPreset={getAssetChangePreset}
          getModalContent={(
            inputType: BlotterInputType,
            label: string,
            editProperty: keyof TransactionAssetChange,
            closeModal: () => void,
            newSelected: readonly string[],
            initialValue: any
          ) => {
            return getBlotterEditModalContent<TransactionAssetChange>(
              inputType,
              label,
              editProperty,
              closeModal,
              handleAssetChangeEdit,
              newSelected,
              initialValue
            )
          }}
          checkboxColor={palette.accent.main}
          backgroundColor={palette.primary.main}
          headerBackgroundColor={palette.background.default}
          headerTextColor={palette.tableHeaderText.main}
          headerActiveTextColor={palette.accent.main}
          bodyDefaultTextColor={palette.tableBodyText.main}
          bodyPrimaryBackgroundColor={palette.tertiary.main}
          redTextColor='red'
          greenTextColor='green'
          dense
        />
      </Box>
      <Button
        onClick={handleSubmission}
        variant='contained'
        style={{
          width: '100%',
          backgroundColor: palette.accent.main,
          color: palette.secondary.main
        }}
      >
        Submit
      </Button>
    </Stack>
  )
}

function FeeTrade (props: {
  submit: (transaction: TransactionCreation) => void
}): React.JSX.Element {
  const { submit } = props
  dayjs.extend(utc)
  const { palette } = useTheme()
  const [trader, setTrader] = useState<LiteTrader | null>(null)
  const [account, setAccount] = useState<LiteAccount | null>(null)
  const [strategy, setStrategy] = useState<LiteStrategy | null>(null)
  const [desk, setDesk] = useState<LiteDesk | null>(null)
  const [time, setTime] = useState<Dayjs | null>(dayjs().utc())
  const [comments, setComments] = useState<string>('')

  // Recieved Leg
  const [recievedInstrument, setRecievedInstrument] = useState<LiteInstrument | null>(null)
  const [recievedAmtStr, setRecievedAmtStr] = useState<string>('')
  const [recievedPriceStr, setRecievedPriceStr] = useState<string>('')
  // Paid Leg
  const [paidInstrument, setPaidInstrument] = useState<LiteInstrument | null>(null)
  const [paidAmtStr, setPaidAmtStr] = useState<string>('')
  const [paidPriceStr, setPaidPriceStr] = useState<string>('')
  // Fee Leg
  const [feeInstrument, setFeeInstrument] = useState<LiteInstrument | null>(null)
  const [feeAmtStr, setFeeAmtStr] = useState<string>('')

  const handleChangeTime = (newTime: Dayjs | null): void => {
    if (newTime !== null) {
      setTime(newTime)
    }
  }

  function handleSubmission (): void {
    if (
      time !== null &&
      account !== null
    ) {
      let assetChanges: TransactionAssetChange[] = []
      if (
        recievedAmtStr !== '' &&
        recievedInstrument !== null
      ) {
        const assetChangeId = uuidv4()
        assetChanges = assetChanges.concat([
          {
            id: `new-${assetChangeId}`,
            time: time.format('YYYY-MM-DD HH:mm'),
            settledTime: time.format('YYYY-MM-DD HH:mm'),
            account,
            strategy: strategy ?? undefined,
            desk: desk ?? undefined,
            instrument: recievedInstrument,
            quantity: Math.abs(parseFloat(recievedAmtStr)),
            price: Math.abs(parseFloat(recievedPriceStr)),
            isFee: false
          }
        ])
      }
      if (
        paidAmtStr !== '' &&
        paidInstrument !== null
      ) {
        const assetChangeId = uuidv4()
        assetChanges = assetChanges.concat([
          {
            id: `new-${assetChangeId}`,
            time: time.format('YYYY-MM-DD HH:mm'),
            settledTime: time.format('YYYY-MM-DD HH:mm'),
            account,
            strategy: strategy ?? undefined,
            desk: desk ?? undefined,
            instrument: paidInstrument,
            quantity: -1 * Math.abs(parseFloat(paidAmtStr)),
            price: Math.abs(parseFloat(paidPriceStr)),
            isFee: false
          }
        ])
      }
      if (
        feeAmtStr !== '' &&
        feeInstrument !== null
      ) {
        const assetChangeId = uuidv4()
        assetChanges = assetChanges.concat([
          {
            id: `new-${assetChangeId}`,
            time: time.format('YYYY-MM-DD HH:mm'),
            settledTime: time.format('YYYY-MM-DD HH:mm'),
            account,
            strategy: strategy ?? undefined,
            desk: desk ?? undefined,
            instrument: feeInstrument,
            quantity: -1 * Math.abs(parseFloat(feeAmtStr)),
            price: 0,
            isFee: true
          }
        ])
      }
      const txnId = uuidv4()
      const txn: TransactionCreation = {
        id: `new-${txnId}`,
        trader: trader ?? undefined,
        expectedSettlementTime: undefined,
        comments,
        entryPlatform: 'Frontend',
        entryPlatformId: `FE-${txnId}`,
        assetChanges,
        openedLiabilities: [],
        closedLiabilities: []
      }
      submit(txn)
    }
  }

  return (
    <Stack spacing={1}>
      <Grid spacing={1} container sx={{ width: '100%' }}>
        <Grid item xs={6}>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Box sx={{ width: '50%' }}>
              <Typography
                sx={{ color: palette.tableBodyText.main }}
              >
                Time
              </Typography>
            </Box>
            <Box sx={{ width: '50%' }}>
              <DateTimePicker
                value={time}
                format='YYYY-MM-DD HH:mm'
                onChange={handleChangeTime}
                closeOnSelect={false}
                ampm={false}
                timeSteps={{ hours: 1, minutes: 1 }}
              />
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <TraderInput
            setTrader={setTrader}
            setNone={(dummy: boolean) => {}}
            allowNone={false}
            mainColor={palette.accent.main}
            secondaryColor={palette.tableBodyText.main}
            enhanced
          />
        </Grid>
        <Grid item xs={6}>
          <StrategyInput
            setStrategy={setStrategy}
            setNone={(dummy: boolean) => {}}
            allowNone={false}
            mainColor={palette.accent.main}
            secondaryColor={palette.tableBodyText.main}
            enhanced
            includeSuperStrategyFilter
          />
        </Grid>
        <Grid item xs={6}>
          <DeskInput
            setDesk={setDesk}
            setNone={(dummy: boolean) => {}}
            allowNone={false}
            mainColor={palette.accent.main}
            secondaryColor={palette.tableBodyText.main}
            enhanced
          />
        </Grid>
        <Grid item xs={6}>
          <StringInput
            value={comments}
            setValue={setComments}
            title='Comments'
            color={palette.accent.main}
          />
        </Grid>
        <Grid item xs={6}>
          <AccountInput
            setAccount={setAccount}
            setNone={(dummy: boolean) => {}}
            allowNone={false}
            mainColor={palette.accent.main}
            secondaryColor={palette.tableBodyText.main}
            enhanced
          />
        </Grid>
        <Grid item xs={12}>
          <LegInput
            legName='Recieved'
            amountStr={recievedAmtStr}
            setAmountStr={setRecievedAmtStr}
            priceStr={recievedPriceStr}
            setPriceStr={setRecievedPriceStr}
            setInstrument={setRecievedInstrument}
          />
        </Grid>
        <Grid item xs={12}>
          <LegInput
            legName='Paid'
            amountStr={paidAmtStr}
            setAmountStr={setPaidAmtStr}
            priceStr={paidPriceStr}
            setPriceStr={setPaidPriceStr}
            setInstrument={setPaidInstrument}
          />
        </Grid>
        <Grid item xs={12}>
          <LegInput
            legName='Fee'
            amountStr={feeAmtStr}
            setAmountStr={setFeeAmtStr}
            setInstrument={setFeeInstrument}
          />
        </Grid>
      </Grid>
      <Button
        onClick={handleSubmission}
        variant='contained'
        style={{
          width: '100%',
          backgroundColor: palette.accent.main,
          color: palette.secondary.main
        }}
      >
        Submit
      </Button>
    </Stack>
  )
}

function StrategyTransfer (props: {
  submit: (transaction: TransactionCreation) => void
}): React.JSX.Element {
  const { submit } = props
  dayjs.extend(utc)
  const { palette } = useTheme()
  const [trader, setTrader] = useState<LiteTrader | null>(null)
  const [account, setAccount] = useState<LiteAccount | null>(null)
  const [recievingStrategy, setRecievingStrategy] = useState<LiteStrategy | null>(null)
  const [recievingDesk, setRecievingDesk] = useState<LiteDesk | null>(null)
  const [sendingStrategy, setSendingStrategy] = useState<LiteStrategy | null>(null)
  const [sendingDesk, setSendingDesk] = useState<LiteDesk | null>(null)
  const [time, setTime] = useState<Dayjs | null>(dayjs().utc())
  const [comments, setComments] = useState<string>('')

  // Recieved Leg
  const [recievedInstrument, setRecievedInstrument] = useState<LiteInstrument | null>(null)
  const [recievedAmtStr, setRecievedAmtStr] = useState<string>('')
  const [recievedPriceStr, setRecievedPriceStr] = useState<string>('')

  const handleChangeTime = (newTime: Dayjs | null): void => {
    if (newTime !== null) {
      setTime(newTime)
    }
  }

  function handleSubmission (): void {
    if (
      time !== null &&
      account !== null &&
      recievingStrategy !== null &&
      sendingStrategy !== null &&
      recievedAmtStr !== '' &&
      recievedInstrument !== null
    ) {
      const txnId = uuidv4()
      const changeId1 = uuidv4()
      const changeId2 = uuidv4()
      const txn: TransactionCreation = {
        id: `new-${txnId}`,
        trader: trader ?? undefined,
        expectedSettlementTime: undefined,
        comments,
        entryPlatform: 'Frontend',
        entryPlatformId: `FE-${txnId}`,
        assetChanges: [
          {
            id: `new-${changeId1}`,
            time: time.format('YYYY-MM-DD HH:mm'),
            settledTime: time.format('YYYY-MM-DD HH:mm'),
            account,
            strategy: recievingStrategy,
            desk: recievingDesk ?? undefined,
            instrument: recievedInstrument,
            quantity: Math.abs(parseFloat(recievedAmtStr)),
            price: Math.abs(parseFloat(recievedPriceStr)),
            isFee: false
          },
          {
            id: `new-${changeId2}`,
            time: time.format('YYYY-MM-DD HH:mm'),
            settledTime: time.format('YYYY-MM-DD HH:mm'),
            account,
            strategy: sendingStrategy,
            desk: sendingDesk ?? undefined,
            instrument: recievedInstrument,
            quantity: -1 * Math.abs(parseFloat(recievedAmtStr)),
            price: Math.abs(parseFloat(recievedPriceStr)),
            isFee: false
          }
        ],
        openedLiabilities: [],
        closedLiabilities: []
      }
      submit(txn)
    }
  }

  return (
    <Stack spacing={1}>
      <Grid spacing={1} container sx={{ width: '100%' }}>
        <Grid item xs={6}>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Box sx={{ width: '50%' }}>
              <Typography
                sx={{ color: palette.tableBodyText.main }}
              >
                Time
              </Typography>
            </Box>
            <Box sx={{ width: '50%' }}>
              <DateTimePicker
                value={time}
                format='YYYY-MM-DD HH:mm'
                onChange={handleChangeTime}
                closeOnSelect={false}
                ampm={false}
                timeSteps={{ hours: 1, minutes: 1 }}
              />
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <TraderInput
            setTrader={setTrader}
            setNone={(dummy: boolean) => {}}
            allowNone={false}
            mainColor={palette.accent.main}
            secondaryColor={palette.tableBodyText.main}
            enhanced
          />
        </Grid>
        <Grid item xs={6}>
          <AccountInput
            setAccount={setAccount}
            setNone={(dummy: boolean) => {}}
            allowNone={false}
            mainColor={palette.accent.main}
            secondaryColor={palette.tableBodyText.main}
            includeEntityFilter
            enhanced
          />
        </Grid>
        <Grid item xs={6}>
          <StringInput
            value={comments}
            setValue={setComments}
            title='Comments'
            color={palette.accent.main}
          />
        </Grid>
        <Grid item xs={12}>
          <LegInput
            legName='Recieved'
            amountStr={recievedAmtStr}
            setAmountStr={setRecievedAmtStr}
            priceStr={recievedPriceStr}
            setPriceStr={setRecievedPriceStr}
            setInstrument={setRecievedInstrument}
          />
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={1} sx={{ width: '100%' }}>
            <Typography
              sx={{ color: palette.tableBodyText.main }}
            >
              From
            </Typography>
            <StrategyInput
              setStrategy={setSendingStrategy}
              setNone={(dummy: boolean) => {}}
              allowNone={false}
              mainColor={palette.accent.main}
              secondaryColor={palette.tableBodyText.main}
              includeSuperStrategyFilter
              enhanced
            />
            <DeskInput
              setDesk={setSendingDesk}
              setNone={(dummy: boolean) => {}}
              allowNone={false}
              mainColor={palette.accent.main}
              secondaryColor={palette.tableBodyText.main}
              enhanced
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={1} sx={{ width: '100%' }}>
            <Typography
              sx={{ color: palette.tableBodyText.main }}
            >
              To
            </Typography>
            <StrategyInput
              setStrategy={setRecievingStrategy}
              setNone={(dummy: boolean) => {}}
              allowNone={false}
              mainColor={palette.accent.main}
              secondaryColor={palette.tableBodyText.main}
              includeSuperStrategyFilter
              enhanced
            />
            <DeskInput
              setDesk={setRecievingDesk}
              setNone={(dummy: boolean) => {}}
              allowNone={false}
              mainColor={palette.accent.main}
              secondaryColor={palette.tableBodyText.main}
              enhanced
            />
          </Stack>
        </Grid>
      </Grid>
      <Button
        onClick={handleSubmission}
        variant='contained'
        style={{
          width: '100%',
          backgroundColor: palette.accent.main,
          color: palette.secondary.main
        }}
      >
        Submit
      </Button>
    </Stack>
  )
}

function AccountTransfer (props: {
  submit: (transaction: TransactionCreation) => void
}): React.JSX.Element {
  const { submit } = props
  dayjs.extend(utc)
  const { palette } = useTheme()
  const [sendingAccount, setSendingAccount] = useState<LiteAccount | null>(null)
  const [recievingAccount, setRecievingAccount] = useState<LiteAccount | null>(null)
  const [strategy, setStrategy] = useState<LiteStrategy | null>(null)
  const [desk, setDesk] = useState<LiteDesk | null>(null)
  const [time, setTime] = useState<Dayjs | null>(dayjs().utc())
  const [comments, setComments] = useState<string>('')

  const [instrument, setInstrument] = useState<LiteInstrument | null>(null)
  const [amountStr, setAmountStr] = useState<string>('')
  const [priceStr, setPriceStr] = useState<string>('')

  // Fee Leg
  const [feeInstrument, setFeeInstrument] = useState<LiteInstrument | null>(null)
  const [feeAmtStr, setFeeAmtStr] = useState<string>('')

  const handleChangeTime = (newTime: Dayjs | null): void => {
    if (newTime !== null) {
      setTime(newTime)
    }
  }

  function handleSubmission (): void {
    if (
      time !== null &&
      sendingAccount !== null &&
      recievingAccount !== null &&
      instrument !== null &&
      amountStr !== ''
    ) {
      const changeId1 = uuidv4()
      const changeId2 = uuidv4()
      let assetChanges: TransactionAssetChange[] = [
        {
          id: `new-${changeId1}`,
          account: recievingAccount,
          time: time.format('YYYY-MM-DD HH:mm'),
          settledTime: time.format('YYYY-MM-DD HH:mm'),
          strategy: strategy ?? undefined,
          desk: desk ?? undefined,
          instrument,
          quantity: Math.abs(parseFloat(amountStr)),
          price: Math.abs(parseFloat(priceStr)),
          isFee: false
        },
        {
          id: `new-${changeId2}`,
          account: sendingAccount,
          time: time.format('YYYY-MM-DD HH:mm'),
          settledTime: time.format('YYYY-MM-DD HH:mm'),
          strategy: strategy ?? undefined,
          desk: desk ?? undefined,
          instrument,
          quantity: -1 * Math.abs(parseFloat(amountStr)),
          price: Math.abs(parseFloat(priceStr)),
          isFee: false
        }
      ]
      if (
        feeAmtStr !== '' &&
        feeInstrument !== null
      ) {
        const feeChangeId = uuidv4()
        assetChanges = assetChanges.concat([
          {
            id: `new-${feeChangeId}`,
            time: time.format('YYYY-MM-DD HH:mm'),
            settledTime: time.format('YYYY-MM-DD HH:mm'),
            account: sendingAccount,
            strategy: strategy ?? undefined,
            desk: desk ?? undefined,
            instrument: feeInstrument,
            quantity: -1 * Math.abs(parseFloat(feeAmtStr)),
            price: 0,
            isFee: true
          }
        ])
      }
      const txnId = uuidv4()
      const txn: TransactionCreation = {
        id: `new-${txnId}`,
        trader: undefined,
        expectedSettlementTime: undefined,
        comments,
        entryPlatform: 'Frontend',
        entryPlatformId: `FE-${txnId}`,
        assetChanges,
        openedLiabilities: [],
        closedLiabilities: []
      }
      submit(txn)
    }
  }

  return (
    <Stack spacing={1}>
      <Grid spacing={1} container sx={{ width: '100%' }}>
        <Grid item xs={6}>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Box sx={{ width: '50%' }}>
              <Typography
                sx={{ color: palette.tableBodyText.main }}
              >
                Time
              </Typography>
            </Box>
            <Box sx={{ width: '50%' }}>
              <DateTimePicker
                value={time}
                format='YYYY-MM-DD HH:mm'
                onChange={handleChangeTime}
                closeOnSelect={false}
                ampm={false}
                timeSteps={{ hours: 1, minutes: 1 }}
              />
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <StringInput
            value={comments}
            setValue={setComments}
            title='Comments'
            color={palette.accent.main}
          />
        </Grid>
        <Grid item xs={6}>
          <StrategyInput
            setStrategy={setStrategy}
            setNone={(dummy: boolean) => {}}
            allowNone={false}
            mainColor={palette.accent.main}
            secondaryColor={palette.tableBodyText.main}
            enhanced={false}
            includeSuperStrategyFilter
          />
        </Grid>
        <Grid item xs={6}>
          <DeskInput
            setDesk={setDesk}
            setNone={(dummy: boolean) => {}}
            allowNone={false}
            mainColor={palette.accent.main}
            secondaryColor={palette.tableBodyText.main}
            enhanced={false}
          />
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={1} sx={{ width: '100%' }}>
            <Typography
              sx={{ color: palette.tableBodyText.main }}
            >
              From
            </Typography>
            <AccountInput
              setAccount={setSendingAccount}
              setNone={(dummy: boolean) => {}}
              allowNone={false}
              mainColor={palette.accent.main}
              secondaryColor={palette.tableBodyText.main}
              includeEntityFilter
              enhanced
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={1} sx={{ width: '100%' }}>
            <Typography
              sx={{ color: palette.tableBodyText.main }}
            >
              To
            </Typography>
            <AccountInput
              setAccount={setRecievingAccount}
              setNone={(dummy: boolean) => {}}
              allowNone={false}
              mainColor={palette.accent.main}
              secondaryColor={palette.tableBodyText.main}
              includeEntityFilter
              enhanced
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <LegInput
            legName='Transferred'
            amountStr={amountStr}
            setAmountStr={setAmountStr}
            priceStr={priceStr}
            setPriceStr={setPriceStr}
            setInstrument={setInstrument}
          />
        </Grid>
        <Grid item xs={12}>
          <LegInput
            legName='Fee'
            amountStr={feeAmtStr}
            setAmountStr={setFeeAmtStr}
            setInstrument={setFeeInstrument}
          />
        </Grid>
      </Grid>
      <Button
        onClick={handleSubmission}
        variant='contained'
        style={{
          width: '100%',
          backgroundColor: palette.accent.main,
          color: palette.secondary.main
        }}
      >
        Submit
      </Button>
    </Stack>
  )
}

function AddTransactionModal (): React.JSX.Element {
  const navigate = useNavigate()
  const [viewOption, setViewOption] = useState<TransactionAdditionSelectOption | undefined>(transactionSelectOptions.at(0))
  const [postPrices] = priceApi.useUpdatePricesMutation()
  const [postAdditions] = blotterApi.useAddTransactionsMutation()
  const { showSnackbar } = useRequestSnackbar()

  function exitModal (): void {
    if ((Boolean(window.history.state)) && window.history.state.idx > 0) {
      navigate(-1)
    } else {
      navigate('/blotter/transactions')
    }
  }

  function addTransaction (transaction: TransactionCreation): void {
    const prices = transaction.assetChanges.filter((ch) => (!(ch.isFee as boolean) && ch.price !== 0)).map((assCh) => {
      const price: Price = {
        id: 'dummyId',
        time: assCh.time,
        instrument: {
          ...assCh.instrument,
          pricingMethodName: assCh.instrument.pricingMethod.name
        },
        price: assCh.price,
        delta: 1.0,
        beta: 1.0
      }
      return price
    }).filter((price) => price.instrument.pricingMethodName === 'Manual')
    showSnackbar({
      isOpen: true,
      message: 'Pushing Transaction',
      status: 'processing'
    })
    postAdditions({ additions: [transaction] })
      .then((value) => {
        if (isApiError(value.error)) {
          console.error(value.error.data)
          const msg = value.error.originalStatus === 400
            ? value.error.data
            : 'Unexpected Error, check logs'
          showSnackbar({
            isOpen: true,
            message: msg,
            status: 'error'
          })
        } else {
          if (prices.length > 0) {
            showSnackbar({
              isOpen: true,
              message: 'Updating Prices',
              status: 'processing'
            })
            postPrices({
              updates: prices
            })
              .then((value) => {
                if (isApiError(value.error)) {
                  console.error(value.error.data)
                  const msg = value.error.originalStatus === 400
                    ? value.error.data
                    : 'Unexpected Error updating Prices, check logs'
                  showSnackbar({
                    isOpen: true,
                    message: msg,
                    status: 'failure'
                  })
                  exitModal()
                } else {
                  showSnackbar({
                    isOpen: true,
                    message: 'Done',
                    status: 'success'
                  })
                  exitModal()
                }
              })
              .catch((error) => {
                console.error(error)
                showSnackbar({
                  isOpen: true,
                  message: 'Failed to update prices',
                  status: 'failure'
                })
                exitModal()
              })
          } else {
            showSnackbar({
              isOpen: true,
              message: 'Done',
              status: 'success'
            })
          }
          exitModal()
        }
      })
      .catch((error) => {
        console.error(error)
        showSnackbar({
          isOpen: true,
          message: 'Failed to Push Transaction',
          status: 'error'
        })
      })
  }

  let content: React.JSX.Element
  switch (viewOption?.type) {
    case 'feeTrade':
      content = <FeeTrade submit={addTransaction}/>
      break
    case 'accountTransfer':
      content = <AccountTransfer submit={addTransaction}/>
      break
    case 'strategyTransfer':
      content = <StrategyTransfer submit={addTransaction}/>
      break
    default:
      content = <Custom submit={addTransaction}/>
  }

  const handleTypeChange = (event: SelectChangeEvent): void => {
    const newType = event.target.value
    const targetOption = transactionSelectOptions.find((option) => option.type === newType)
    setViewOption(targetOption)
  }

  return (
    <Modal
      open
      handleClose={exitModal}
    >
      <Box
        sx={{
          width: `${viewOption?.width ?? 90}vw`,
          maxHeight: '95vh',
          overflow: 'scroll'
        }}
      >
        <Stack direction={'column'} padding={3} spacing={1}>
          <Box textAlign={'left'} sx={{ width: '100%' }}>
            <Select
              value={viewOption?.type ?? 'basicLoan'}
              onChange={handleTypeChange}
              sx={{ width: '15vw' }}
              color="accent"
            >
              {
                transactionSelectOptions.map(
                  (option, i) => {
                    return (
                      <MenuItem value={option.type} key={i}>
                        {option.display}
                      </MenuItem>
                    )
                  }
                )
              }
            </Select>
          </Box>
          {content}
        </Stack>
      </Box>
    </Modal>
  )
}

export default AddTransactionModal
