import React, { useEffect, useState } from 'react'
import { Box, Grid, Stack, useTheme } from '@mui/material'
import { Modal, useRequestSnackbar } from '@r40cap/ui'
import {
  type Trader,
  type Transaction,
  blotterApi
} from '@r40cap/pms-sdk'

import CardDisplay from './CardDisplay'
import { BlotterInputType } from '../../common/types'
import PushButton from '../../../utils/PushButton'
import { isApiError } from '../../../../utils/errors'

function TransactionPanel (props: {
  transaction: Transaction | null
  isFetching: boolean
  resetSignal: boolean
}): React.JSX.Element {
  const { transaction, isFetching, resetSignal } = props
  const { palette } = useTheme()
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false)
  const [modalContent, setModalContent] = useState<React.JSX.Element>(<></>)
  const [editedTransaction, setEditedTransaction] = useState<Transaction | null>(transaction)
  const [pushEditedTransaction] = blotterApi.useEditTransactionByIdMutation()
  const { showSnackbar } = useRequestSnackbar()

  useEffect(() => {
    setEditedTransaction(transaction)
  }, [transaction, resetSignal])

  function setTrader (value: Trader): void {
    if (editedTransaction !== null) {
      setEditedTransaction({
        ...editedTransaction,
        trader: value
      })
    }
  }

  function setExpectedSettlementTime (value: string): void {
    if (editedTransaction !== null) {
      setEditedTransaction({
        ...editedTransaction,
        expectedSettlementTime: value
      })
    }
  }

  function setComments (value: string): void {
    if (editedTransaction !== null) {
      setEditedTransaction({
        ...editedTransaction,
        comments: value
      })
    }
  }

  function handlePush (): void {
    if (editedTransaction !== null) {
      showSnackbar({
        isOpen: true,
        message: 'Editing Transaction',
        status: 'processing'
      })
      pushEditedTransaction({
        edit: {
          id: editedTransaction.id,
          trader: editedTransaction.trader,
          expectedSettlementTime: editedTransaction.expectedSettlementTime,
          comments: editedTransaction.comments,
          entryPlatform: editedTransaction.entryPlatform,
          entryPlatformId: editedTransaction.entryPlatformId
        }
      })
        .then((value) => {
          if (isApiError(value.error)) {
            console.error(value.error.data)
            const msg = value.error.originalStatus === 400
              ? value.error.data
              : 'Unexpected Error, check logs'
            showSnackbar({
              isOpen: true,
              message: msg,
              status: 'error'
            })
          } else {
            showSnackbar({
              isOpen: true,
              message: 'Done',
              status: 'success'
            })
          }
        })
        .catch((error) => {
          console.error(error)
          showSnackbar({
            isOpen: true,
            message: 'Failed to edit transaction',
            status: 'error'
          })
        })
    }
  }

  return (
    <>
      <Stack sx={{ width: '100%' }} direction='column'>
        <Box sx={{ width: '100%' }} textAlign='right'>
          {
            transaction !== editedTransaction && <PushButton
              pushFunction={handlePush}
            />
          }
        </Box>
        <Grid container sx={{ width: '100%' }}>
          <Grid item xs={3} sx={{ padding: '5px', height: '90px' }}>
            <CardDisplay<Transaction>
              title={'Trader'}
              value={
                editedTransaction?.trader === undefined || editedTransaction?.trader === null
                  ? undefined
                  : `${editedTransaction.trader.lastName}, ${editedTransaction.trader.firstName}`
              }
              backgroundColor={palette.primary.main}
              titleTextColor={palette.tableBodyText.main}
              valueTextColor={palette.tableBodyText.main}
              editType={BlotterInputType.TRADER}
              openModal={(content: React.JSX.Element) => {
                setModalContent(content)
                setEditModalOpen(true)
              }}
              closeModal={() => {
                setModalContent(<></>)
                setEditModalOpen(false)
              }}
              editProperty='trader'
              onSubmission={setTrader}
              enabled
              isFetching={isFetching}
            />
          </Grid>
          <Grid item xs={3} sx={{ padding: '5px', height: '90px' }}>
            <CardDisplay<Transaction>
              title={'Settlement Time'}
              value={editedTransaction?.expectedSettlementTime}
              backgroundColor={palette.primary.main}
              titleTextColor={palette.tableBodyText.main}
              valueTextColor={palette.tableBodyText.main}
              editType={BlotterInputType.TIME}
              openModal={(content: React.JSX.Element) => {
                setModalContent(content)
                setEditModalOpen(true)
              }}
              closeModal={() => {
                setModalContent(<></>)
                setEditModalOpen(false)
              }}
              editProperty='expectedSettlementTime'
              onSubmission={setExpectedSettlementTime}
              enabled
              isFetching={isFetching}
            />
          </Grid>
          <Grid item xs={6} sx={{ padding: '5px', height: '90px' }}>
            <CardDisplay<Transaction>
              title={'Comments'}
              value={editedTransaction?.comments}
              backgroundColor={palette.primary.main}
              titleTextColor={palette.tableBodyText.main}
              valueTextColor={palette.tableBodyText.main}
              editType={BlotterInputType.STRING}
              openModal={(content: React.JSX.Element) => {
                setModalContent(content)
                setEditModalOpen(true)
              }}
              closeModal={() => {
                setModalContent(<></>)
                setEditModalOpen(false)
              }}
              editProperty='comments'
              onSubmission={setComments}
              enabled
              isFetching={isFetching}
            />
          </Grid>
          <Grid item xs={4} sx={{ padding: '5px', height: '90px' }}>
            <CardDisplay<Transaction>
              title={'Entry Method'}
              value={editedTransaction?.entryPlatform}
              backgroundColor={palette.primary.main}
              titleTextColor={palette.tableBodyText.main}
              valueTextColor={palette.tableBodyText.main}
              editType={BlotterInputType.NUMBER}
              openModal={(content: React.JSX.Element) => {}}
              closeModal={() => {
                setModalContent(<></>)
                setEditModalOpen(false)
              }}
              onSubmission={(value: string) => {}}
              enabled
              isFetching={isFetching}
            />
          </Grid>
          <Grid item xs={8} sx={{ padding: '5px', height: '90px' }}>
            <CardDisplay<Transaction>
              title={'Platform ID'}
              value={editedTransaction?.entryPlatformId}
              backgroundColor={palette.primary.main}
              titleTextColor={palette.tableBodyText.main}
              valueTextColor={palette.tableBodyText.main}
              editType={BlotterInputType.STRING}
              openModal={(content: React.JSX.Element) => {}}
              closeModal={() => {
                setModalContent(<></>)
                setEditModalOpen(false)
              }}
              onSubmission={(value: string) => {}}
              enabled
              isFetching={isFetching}
              copyable
            />
          </Grid>
        </Grid>
      </Stack>
      <Modal
        open={editModalOpen}
        handleClose={() => {
          setModalContent(<></>)
          setEditModalOpen(false)
        }}
      >
        {modalContent}
      </Modal>
    </>
  )
}

export default TransactionPanel
