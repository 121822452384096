import React, { useState } from 'react'
import dayjs, { type Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Box, Button, Stack, useTheme } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import { BooleanInput, StringInput, NumberInput } from '@r40cap/ui'
import { useAuth } from '@r40cap/auth'
import {
  type LiteDesk,
  DeskInput,
  type LiteEntity,
  EntityInput,
  type LiteStrategy,
  StrategyInput,
  type LiteInstrument,
  InstrumentInput,
  type LiteAccount,
  AccountInput,
  type LiteTrader,
  TraderInput,
  type LiteInvestor,
  InvestorInput
} from '@r40cap/pms-sdk'

export function NumberEditContent (props: {
  title: string
  closeModal: () => void
  submit: (value: number) => void
}): React.JSX.Element {
  const { title, submit, closeModal } = props
  const [valueString, setValueString] = useState<string>('')
  const { palette } = useTheme()

  const handleEnterSubmission = (value: number): void => {
    submit(value)
    closeModal()
  }

  const handleSubmission = (): void => {
    if (valueString !== '') {
      const result = parseFloat(valueString)
      submit(result)
    }
    closeModal()
  }

  return (
    <Box
      sx={{ width: '17vw', margin: '1vw' }}
    >
      <Stack direction={'column'} spacing={2}>
        <NumberInput
          title={title}
          intOnly={false}
          valueString={valueString}
          setValueString={setValueString}
          submit={handleEnterSubmission}
          defaultValue={0}
          defaultPrecision={1}
          color={palette.accent.main}
        />
        <Button
          variant='contained'
          onClick={handleSubmission}
          sx={{
            backgroundColor: palette.accent.main,
            color: palette.background.default,
            width: '50%',
            alignSelf: 'center'
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}

export function StringEditContent (props: {
  title: string
  closeModal: () => void
  submit: (value: string) => void
}): React.JSX.Element {
  const { title, submit, closeModal } = props
  const { palette } = useTheme()
  const [value, setValue] = useState<string>('')

  function handleSubmit (): void {
    submit(value)
    closeModal()
  }

  return (
    <Box
      sx={{ width: '25vw', margin: '1vw' }}
    >
      <Stack direction={'column'} spacing={2}>
        <StringInput
          title={title}
          value={value}
          setValue={setValue}
          enterSubmit={handleSubmit}
          color={palette.accent.main}
        />
        <Button
          variant='contained'
          onClick={handleSubmit}
          sx={{
            backgroundColor: palette.accent.main,
            color: palette.background.default,
            width: '50%',
            alignSelf: 'center'
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}

export function BooleanEditContent (props: {
  title: string
  closeModal: () => void
  submit: (value: boolean) => void
}): React.JSX.Element {
  const { title, closeModal, submit } = props
  const { palette } = useTheme()

  const [value, setValue] = useState<boolean>(false)

  const handleSubmission = (): void => {
    submit(value)
    closeModal()
  }

  return (
    <Box
      sx={{ width: '15vw', margin: '1vw' }}
    >
      <Stack direction={'column'} spacing={2}>
        <BooleanInput
          setValue={setValue}
          value={value}
          label={title}
          boxColor={palette.accent.main}
          textColor={palette.background.default}
        />
        <Button
          variant='contained'
          onClick={handleSubmission}
          sx={{
            backgroundColor: palette.accent.main,
            color: palette.background.default,
            width: '50%',
            alignSelf: 'center'
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}

export function TimeEditContent (props: {
  closeModal: () => void
  submit: (value: Dayjs) => void
}): React.JSX.Element {
  dayjs.extend(utc)
  const { closeModal, submit } = props
  const [value, setValue] = useState<Dayjs | null>(dayjs().utc())
  const { palette } = useTheme()

  const handleSubmission = (): void => {
    if (value !== null) {
      submit(value)
      closeModal()
    }
  }

  const handleChangeTime = (newTime: Dayjs | null): void => {
    if (newTime !== null) {
      setValue(newTime)
    }
  }

  return (
    <Box
      sx={{ width: '25vw', margin: '1vw' }}
    >
      <Stack direction={'column'} spacing={2}>
        <DateTimePicker
          value={value}
          format='YYYY-MM-DD HH:mm'
          onChange={handleChangeTime}
          closeOnSelect={false}
          ampm={false}
          timeSteps={{ hours: 1, minutes: 1 }}
        />
        <Button
          variant='contained'
          onClick={handleSubmission}
          sx={{
            backgroundColor: palette.accent.main,
            color: palette.background.default,
            width: '50%',
            alignSelf: 'center'
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}

export function EntityEditContent (props: {
  closeModal: () => void
  submit: (value: LiteEntity) => void
  prefillEntityId?: string | null
}): React.JSX.Element {
  const { closeModal, submit, prefillEntityId } = props
  const { palette } = useTheme()
  const [value, setValue] = useState<LiteEntity | null>(null)

  const handleSubmission = (): void => {
    if (value !== null) {
      submit(value)
      closeModal()
    }
  }

  return (
    <Box
      sx={{ width: '20vw', margin: '1vw' }}
    >
      <Stack direction={'column'} spacing={2}>
        <EntityInput
          setEntity={setValue}
          setNone={(dummy: boolean) => {}}
          allowNone={false}
          mainColor={palette.accent.main}
          secondaryColor={palette.tableBodyText.main}
          enhanced={true}
          prefillEntityId={prefillEntityId}
        />
        <Button
          variant='contained'
          onClick={handleSubmission}
          sx={{
            backgroundColor: palette.accent.main,
            color: palette.background.default,
            width: '50%',
            alignSelf: 'center'
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}

export function StrategyEditContent (props: {
  closeModal: () => void
  submit: (value: LiteStrategy) => void
  prefillStrategyId?: string | null
}): React.JSX.Element {
  const { closeModal, submit, prefillStrategyId } = props
  const { palette } = useTheme()
  const [value, setValue] = useState<LiteStrategy | null>(null)

  const handleSubmission = (): void => {
    if (value !== null) {
      submit(value)
      closeModal()
    }
  }

  return (
    <Box
      sx={{ width: '40vw', margin: '1vw' }}
    >
      <Stack direction={'column'} sx={{ width: '100%' }} spacing={2}>
        <StrategyInput
          setStrategy={setValue}
          setNone={(dummy: boolean) => {}}
          allowNone={false}
          mainColor={palette.accent.main}
          secondaryColor={palette.tableBodyText.main}
          enhanced
          includeSuperStrategyFilter
          prefillStrategyId={prefillStrategyId}
        />
        <Button
          variant='contained'
          onClick={handleSubmission}
          sx={{
            backgroundColor: palette.accent.main,
            color: palette.background.default,
            width: '50%',
            alignSelf: 'center'
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}

export function TagContent (props: {
  closeModal: () => void
  submit: (strategy: LiteStrategy, desk: LiteDesk) => void
  prefillStrategyId?: string | null
}): React.JSX.Element {
  const { closeModal, submit, prefillStrategyId } = props
  const { palette } = useTheme()
  const [strategy, setStrategy] = useState<LiteStrategy | null>(null)
  const [desk, setDesk] = useState<LiteDesk | null>(null)
  const authConext = useAuth()

  const handleSubmission = (): void => {
    if (strategy !== null && (desk !== null || authConext.restrictedDeskId !== undefined || authConext.restrictedBlotterDeskId !== undefined)) {
      if (authConext.restrictedDeskId !== undefined) {
        submit(strategy, { id: authConext.restrictedDeskId, name: '' })
      } else if (authConext.restrictedBlotterDeskId !== undefined) {
        submit(strategy, { id: authConext.restrictedBlotterDeskId, name: '' })
      } else if (desk !== null) {
        submit(strategy, desk)
      }
      closeModal()
    }
  }

  return (
    <Box sx={{ width: '40vw', margin: '1vw' }}>
      <Stack direction={'column'} sx={{ width: '100%' }} spacing={2}>
        <StrategyInput
          setStrategy={setStrategy}
          setNone={(dummy: boolean) => {}}
          allowNone={false}
          mainColor={palette.accent.main}
          secondaryColor={palette.tableBodyText.main}
          enhanced
          includeSuperStrategyFilter
          prefillStrategyId={prefillStrategyId}
        />
        {
          authConext.restrictedDeskId === undefined && authConext.restrictedBlotterDeskId === undefined && <DeskInput
            setDesk={setDesk}
            setNone={(dummy: boolean) => {}}
            allowNone={false}
            mainColor={palette.accent.main}
            secondaryColor={palette.tableBodyText.main}
            enhanced
          />
        }
        <Button
          variant='contained'
          onClick={handleSubmission}
          sx={{
            backgroundColor: palette.accent.main,
            color: palette.background.default,
            width: '50%',
            alignSelf: 'center'
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}

export function DeskEditContent (props: {
  closeModal: () => void
  submit: (value: LiteDesk) => void
  prefillDeskId?: string | null
}): React.JSX.Element {
  const { closeModal, submit, prefillDeskId } = props
  const { palette } = useTheme()
  const [value, setValue] = useState<LiteDesk | null>(null)

  const handleSubmission = (): void => {
    if (value !== null) {
      submit(value)
      closeModal()
    }
  }

  return (
    <Box
      sx={{ width: '20vw', margin: '1vw' }}
    >
      <Stack
        direction={'column'}
        sx={{ width: '100%' }}
        spacing={2}
      >
        <DeskInput
          setDesk={setValue}
          setNone={(dummy: boolean) => {}}
          allowNone={false}
          mainColor={palette.accent.main}
          secondaryColor={palette.tableBodyText.main}
          prefillDeskId={prefillDeskId}
          enhanced={true}
        />
        <Button
          variant='contained'
          onClick={handleSubmission}
          sx={{
            backgroundColor: palette.accent.main,
            color: palette.background.default,
            width: '50%',
            alignSelf: 'center'
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}

export function TraderEditContent (props: {
  closeModal: () => void
  submit: (value: LiteTrader) => void
  prefillTraderId?: string | null
}): React.JSX.Element {
  const { closeModal, submit, prefillTraderId } = props
  const { palette } = useTheme()
  const [value, setValue] = useState<LiteTrader | null>(null)

  const handleSubmission = (): void => {
    if (value !== null) {
      submit(value)
      closeModal()
    }
  }

  return (
    <Box
      sx={{ width: '40vw', margin: '1vw' }}
    >
      <Stack direction={'column'} sx={{ width: '100%' }} spacing={2}>
        <TraderInput
          setTrader={setValue}
          setNone={(dummy: boolean) => {}}
          allowNone={false}
          mainColor={palette.accent.main}
          secondaryColor={palette.tableBodyText.main}
          enhanced
          prefillTraderId={prefillTraderId}
        />
        <Button
          variant='contained'
          onClick={handleSubmission}
          sx={{
            backgroundColor: palette.accent.main,
            color: palette.background.default,
            width: '50%',
            alignSelf: 'center'
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}

export function InvestorEditContent (props: {
  closeModal: () => void
  submit: (value: LiteInvestor) => void
  prefillInvestorId?: string | null
}): React.JSX.Element {
  const { closeModal, submit, prefillInvestorId } = props
  const { palette } = useTheme()
  const [value, setValue] = useState<LiteInvestor | null>(null)

  const handleSubmission = (): void => {
    if (value !== null) {
      submit(value)
      closeModal()
    }
  }

  return (
    <Box
      sx={{ width: '40vw', margin: '1vw' }}
    >
      <Stack direction={'column'} sx={{ width: '100%' }} spacing={2}>
        <InvestorInput
          setInvestor={setValue}
          setNone={(dummy: boolean) => {}}
          allowNone={false}
          mainColor={palette.accent.main}
          secondaryColor={palette.tableBodyText.main}
          enhanced
          prefillInvestorId={prefillInvestorId}
        />
        <Button
          variant='contained'
          onClick={handleSubmission}
          sx={{
            backgroundColor: palette.accent.main,
            color: palette.background.default,
            width: '50%',
            alignSelf: 'center'
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}

export function InstrumentEditContent (props: {
  closeModal: () => void
  submit: (value: LiteInstrument) => void
  prefillInstrumentId?: string | null
}): React.JSX.Element {
  const { closeModal, submit, prefillInstrumentId } = props
  const { palette } = useTheme()
  const [value, setValue] = useState<LiteInstrument | null>(null)

  const handleSubmission = (): void => {
    if (value !== null) {
      submit(value)
      closeModal()
    }
  }

  return (
    <Box
      sx={{ width: '40vw', margin: '1vw' }}
    >
      <Stack direction={'column'} spacing={2}>
        <InstrumentInput
          setInstrument={setValue}
          setNone={(dummy: boolean) => {}}
          allowNone={false}
          mainColor={palette.accent.main}
          secondaryColor={palette.tableBodyText.main}
          callColor={palette.success.main}
          putColor={palette.error.main}
          enhanced
          includeBaseFxFilter
          includeInstrumentTypeFilter
          prefillInstrumentId={prefillInstrumentId}
        />
        <Button
          variant='contained'
          onClick={handleSubmission}
          sx={{
            backgroundColor: palette.accent.main,
            color: palette.background.default,
            width: '50%',
            alignSelf: 'center'
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}

export function AccountEditContent (props: {
  closeModal: () => void
  submit: (value: LiteAccount) => void
  prefillAccountId?: string | null
}): React.JSX.Element {
  const { closeModal, submit, prefillAccountId } = props
  const { palette } = useTheme()
  const [value, setValue] = useState<LiteAccount | null>(null)

  const handleSubmission = (): void => {
    if (value !== null) {
      submit(value)
      closeModal()
    }
  }

  return (
    <Box
      sx={{ width: '30vw', margin: '1vw' }}
    >
      <Stack direction={'column'} spacing={2}>
        <AccountInput
          setAccount={setValue}
          setNone={(dummy: boolean) => {}}
          allowNone={false}
          mainColor={palette.accent.main}
          secondaryColor={palette.tableBodyText.main}
          enhanced
          includeEntityFilter
          prefillAccountId={prefillAccountId}
        />
        <Button
          variant='contained'
          onClick={handleSubmission}
          sx={{
            backgroundColor: palette.accent.main,
            color: palette.background.default,
            width: '50%',
            alignSelf: 'center'
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}
