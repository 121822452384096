import { type Order } from '@r40cap/ui'

export function getAbsoluteValueComparator<Key extends keyof any> (
  order: Order,
  orderBy: Key
) {
  return (
    a: Partial<{ [key in Key]: number | string | boolean | null }>,
    b: Partial<{ [key in Key]: number | string | boolean | null }>
  ) => {
    const aValue = Math.abs(a[orderBy] as number)
    const bValue = Math.abs(b[orderBy] as number)

    return order === 'desc'
      ? bValue - aValue
      : aValue - bValue
  }
}
