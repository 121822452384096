import {
  type PortfolioViewOption,
  PortfolioViewType,
  type SingleReferenceTimeOption,
  SingleReferenceTimeType,
  type BaseFxOption
} from './types'

export const portfolioViewOptions: PortfolioViewOption[] = [
  {
    display: 'Positions',
    type: PortfolioViewType.POSITIONS
  },
  {
    display: 'Balances',
    type: PortfolioViewType.BALANCES
  },
  {
    display: 'Liabilities',
    type: PortfolioViewType.LIABILITIES
  },
  {
    display: 'Unsettled',
    type: PortfolioViewType.UNSETTLED
  }
]

export const singleReferenceTimingOptions: SingleReferenceTimeOption[] = [
  {
    label: 'Live',
    type: SingleReferenceTimeType.CURRENT,
    value: 'live'
  },
  {
    label: 'Last Mark',
    type: SingleReferenceTimeType.LAST_MARK,
    value: 'last_mark'
  },
  {
    label: 'Specify EOD',
    type: SingleReferenceTimeType.EOD,
    value: 'eod'
  }
]

export const baseFxOptions: BaseFxOption[] = [
  {
    id: '05324b40-cbec-4d38-8032-6d22d4bcdaa6',
    label: 'ETH'
  },
  {
    id: '0269eaf9-3c74-4aa0-a9d0-e2f0a053c79b',
    label: 'BTC'
  }
]
