import { type ColumnDefinition } from '@r40cap/ui'
import type { CollateralItem, PositionItem } from '@r40cap/pms-sdk'

import type { CollateralRow, PositionRow } from './types'
import { InputType } from '../types'

export const collateralColumns: Array<ColumnDefinition<CollateralRow, InputType, CollateralItem>> = [
  {
    label: 'Asset',
    id: 'ticker',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'Balance',
    id: 'balance',
    columnWidthPercentage: 22,
    overflowType: 'scroll',
    inputType: InputType.FLOAT,
    editId: 'balance',
    sortable: true,
    colored: false,
    decimalsId: 'quantityDecimals',
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'Frozen',
    id: 'frozenBalance',
    columnWidthPercentage: 22,
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    decimalsId: 'quantityDecimals',
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'Price',
    id: 'price',
    columnWidthPercentage: 18,
    overflowType: 'scroll',
    inputType: InputType.FLOAT,
    editId: 'price',
    sortable: true,
    colored: false,
    decimalsId: 'priceDecimals',
    valueDetails: {
      alignment: 'right',
      prefix: '$',
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'MV',
    id: 'marketValue',
    columnWidthPercentage: 22,
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    decimals: 0,
    valueDetails: {
      alignment: 'right',
      prefix: '$',
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  }
]

export const positionColumns: Array<ColumnDefinition<PositionRow, InputType, PositionItem>> = [
  {
    label: 'Position',
    id: 'ticker',
    columnWidthPercentage: 15,
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'Quantity',
    id: 'quantity',
    columnWidthPercentage: 16,
    overflowType: 'scroll',
    inputType: InputType.FLOAT,
    editId: 'quantity',
    sortable: true,
    colored: false,
    decimalsId: 'quantityDecimals',
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'Price',
    id: 'price',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: InputType.PRICE_INFO,
    editId: 'priceInfo',
    sortable: false,
    colored: false,
    decimalsId: 'priceDecimals',
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'UPL',
    id: 'unrealizedPnl',
    columnWidthPercentage: 14,
    overflowType: 'scroll',
    sortable: false,
    colored: true,
    decimals: 2,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'MMR',
    id: 'marginRequirementCoefficient',
    columnWidthPercentage: 7,
    overflowType: 'scroll',
    sortable: false,
    colored: false,
    decimals: 2,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'FR',
    id: 'annualizedFundingRatePct',
    columnWidthPercentage: 11,
    overflowType: 'scroll',
    sortable: false,
    colored: true,
    decimals: 2,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: '%'
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'MV',
    id: 'marketValue',
    columnWidthPercentage: 15,
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    decimals: 0,
    valueDetails: {
      alignment: 'right',
      prefix: '$',
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  }
]
