import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, useTheme } from '@mui/material'
import { Modal, useRequestSnackbar } from '@r40cap/ui'
import { type BaseFx, BaseFxCreator, glossaryApi } from '@r40cap/pms-sdk'
import { isApiError } from '../../../utils/errors'

function AddBaseFxModal (): React.JSX.Element {
  const navigate = useNavigate()
  const { palette } = useTheme()
  const { showSnackbar } = useRequestSnackbar()
  const [postAdditions] = glossaryApi.useAddBaseFxsMutation()

  function exitModal (): void {
    if ((Boolean(window.history.state)) && window.history.state.idx > 0) {
      navigate(-1)
    } else {
      navigate('/glossary/baseFxs')
    }
  }

  function addBaseFx (baseFx: BaseFx): void {
    showSnackbar({
      isOpen: true,
      message: 'Creating Base FX',
      status: 'processing'
    })
    postAdditions({
      additions: [baseFx]
    })
      .then((value) => {
        if (isApiError(value.error)) {
          console.error(value.error.data)
          const msg = value.error.originalStatus === 400
            ? value.error.data
            : 'Unexpected Error, check logs'
          showSnackbar({
            isOpen: true,
            message: msg,
            status: 'error'
          })
        } else {
          showSnackbar({
            isOpen: true,
            message: 'Created Base FX',
            status: 'success'
          })
          exitModal()
        }
      })
      .catch((error) => {
        console.error(error)
        showSnackbar({
          isOpen: true,
          message: 'Failed to create Base FX',
          status: 'error'
        })
      })
  }

  return (
    <Modal
      open
      handleClose={exitModal}
    >
      <Box
        sx={{
          width: '15vw',
          padding: '20px'
        }}
      >
        <BaseFxCreator
          submitFunction={addBaseFx}
          mainColor={palette.accent.main}
          secondaryColor={palette.tableBodyText.main}
          spacing={2}
        />
      </Box>
    </Modal>
  )
}

export default AddBaseFxModal
