import React, { useEffect, useState } from 'react'
import { useRequestSnackbar } from '@r40cap/ui'
import { type InstrumentType, glossaryApi } from '@r40cap/pms-sdk'

import GlossaryTable from './GlossaryTable'
import { instrumentTypeColumns } from './constants'
import type { TableProps } from './types'
import { isApiError } from '../../../utils/errors'

function InstrumentTypeTable (props: TableProps): React.JSX.Element {
  const {
    refreshSignal,
    pushSignal,
    includeDeprecated,
    editedList,
    markEdited,
    clearEdited
  } = props
  const { showSnackbar } = useRequestSnackbar()
  const [editedData, setEditedData] = useState<InstrumentType[]>([])
  const [clearSelectedSignal, setClearSelectedSignal] = useState<boolean>(false)
  const [postEditsMutation] = glossaryApi.useEditInstrumentTypesMutation()
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const [queryParams, setQueryParams] = useState({
    getAll: includeDeprecated,
    force: false
  })

  const { data, refetch, isFetching } = glossaryApi.useGetInstrumentTypesQuery(queryParams, {
    skip: isInitialLoad
  })

  useEffect(() => {
    if (isInitialLoad) {
      setIsInitialLoad(false)
    }
  }, [isInitialLoad])

  useEffect(() => {
    setQueryParams({
      getAll: includeDeprecated,
      force: false
    })
  }, [includeDeprecated])

  const handleRefresh = (): void => {
    if (!isInitialLoad) {
      if (!queryParams.force) {
        setQueryParams((prevParams) => ({
          ...prevParams,
          force: true
        }))
      } else {
        refetch().then(() => {
          clear()
        }).catch(() => {
          console.error('Error Refreshing')
        })
      }
    }
  }

  const handleEdit = (property: keyof InstrumentType, value: any, selected: readonly string[]): void => {
    const updatedData = editedData.map(item =>
      selected.includes(item.id) ? { ...item, [property]: value } : item
    )
    setEditedData(updatedData)
    markEdited(selected)
  }

  const getPreset = (rowId: string, property: keyof InstrumentType): any => {
    const relevantObject = editedData.find((dataRow: InstrumentType) => dataRow.id === rowId)
    if (relevantObject === undefined) {
      return undefined
    } else {
      return relevantObject[property]
    }
  }

  useEffect(() => {
    if (data !== null && data !== undefined) {
      setEditedData(data.data)
    }
  }, [data])

  useEffect(() => {
    const editedEditObjects: InstrumentType[] = editedData.filter((data) => editedList.includes(data.id))
    if (editedEditObjects.length > 0) {
      showSnackbar({
        isOpen: true,
        message: 'Pushing Instrument Type Edits',
        status: 'processing'
      })
      postEditsMutation({ edits: editedEditObjects })
        .then((value) => {
          if (isApiError(value.error)) {
            console.error(value.error.data)
            const msg = value.error.originalStatus === 400
              ? value.error.data
              : 'Unexpected Error, check logs'
            showSnackbar({
              isOpen: true,
              message: msg,
              status: 'error'
            })
          } else {
            showSnackbar({
              isOpen: true,
              message: 'Instrument Type edits Pushed',
              status: 'success'
            })
            clearEdited()
          }
        })
        .catch((error) => {
          console.error(error)
          showSnackbar({
            isOpen: true,
            message: 'Failed to push edits',
            status: 'error'
          })
        })
    }
  }, [pushSignal])

  useEffect(() => {
    handleRefresh()
  }, [refreshSignal])

  function clear (): void {
    setClearSelectedSignal(!clearSelectedSignal)
  }

  return (
    <GlossaryTable<InstrumentType, InstrumentType>
      rows={editedData}
      columns={instrumentTypeColumns}
      getPreset={getPreset}
      defaultOrderBy='name'
      isFetching={isFetching}
      editSubmission={handleEdit}
      clearSelectedSignal={clearSelectedSignal}
    />
  )
}

export default InstrumentTypeTable
