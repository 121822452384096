import React, { useState } from 'react'
import { Box, Button, Stack, useTheme } from '@mui/material'
import { NumberInput } from '@r40cap/ui'

export function NumberEditContent (props: {
  title: string
  closeModal: () => void
  submit: (value: number) => void
}): React.JSX.Element {
  const { title, submit, closeModal } = props
  const [valueString, setValueString] = useState<string>('')
  const { palette } = useTheme()

  const handleEnterSubmission = (value: number): void => {
    submit(value)
    closeModal()
  }

  const handleSubmission = (): void => {
    if (valueString !== '') {
      const result = parseFloat(valueString)
      submit(result)
    }
    closeModal()
  }

  return (
    <Box
      sx={{ width: '17vw', margin: '1vw' }}
    >
      <Stack direction={'column'} spacing={2}>
        <NumberInput
          title={title}
          intOnly={false}
          valueString={valueString}
          setValueString={setValueString}
          submit={handleEnterSubmission}
          defaultValue={0}
          defaultPrecision={1}
          color={palette.accent.main}
        />
        <Button
          variant='contained'
          onClick={handleSubmission}
          sx={{
            backgroundColor: palette.accent.main,
            color: palette.background.default,
            width: '50%',
            alignSelf: 'center'
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}
