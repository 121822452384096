import React, { useEffect, useState } from 'react'
import { riskApi } from '@r40cap/pms-sdk'

import PlatformBalancesSubTableRow from './PlatformBalancesSubTableRow'
import PlatformPositionsSubTableRow from './PlatformPositionsSubTableRow'
import { accountColumns } from '../constants'

function PlatformRows (props: {
  accountId: string
  includeSmallAmounts: boolean
  smallThreshold: number
  refreshSignal: boolean
}): React.JSX.Element {
  const {
    accountId,
    includeSmallAmounts,
    smallThreshold,
    refreshSignal
  } = props
  const [priceMap, setPriceMap] = useState<Map<string, number>>(new Map<string, number>())

  const { data: psnsData, isFetching: psnsIsFetching, refetch: psnsRefetch } = riskApi.useGetPlatformPositionsQuery({ accountId })
  const { data: collateralData, isFetching: collateralIsFetching, refetch: collateralRefetch } = riskApi.useGetPlatformCollateralQuery({ accountId })

  useEffect(() => {
    psnsRefetch().catch((error) => { console.error('Error Refreshing Positions', error) })
    collateralRefetch().catch((error) => { console.error('Error Refreshing Collateral', error) })
  }, [refreshSignal])

  useEffect(() => {
    const newPriceMap = new Map<string, number>()
    if (collateralData?.data !== undefined) {
      collateralData.data.forEach(item => newPriceMap.set(item.id, item.price))
    }
    setPriceMap(newPriceMap)
  }, [collateralData])

  return <>
    <PlatformBalancesSubTableRow
      colsToSpan={accountColumns.length + 1}
      isFetching={collateralIsFetching}
      containedRows={collateralData?.data ?? []}
      includeSmallAmounts={includeSmallAmounts}
      smallThreshold={smallThreshold}
    />
    <PlatformPositionsSubTableRow
      colsToSpan={accountColumns.length + 1}
      isFetching={psnsIsFetching}
      containedRows={psnsData?.data ?? []}
      includeSmallAmounts={includeSmallAmounts}
      smallThreshold={smallThreshold}
      currencyPriceMap={priceMap}
    />
  </>
}

export default PlatformRows
